.MuiTableContainer-root>tbody>tr {
    height: 4.8rem;
    box-shadow: inset -1px 0px 0px #F4F4F4 !important;
}

.MuiTableCell-root {
    padding: 0.5rem 0.5rem 0.5rem 1.6rem !important;
    border-bottom: solid 0.5px #FAFAFA;
}

.MuiDataGrid-row {
    height: 56px;
}

.css-19f67a2-MuiButtonBase-root-MuiPaginationItem-root {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
}

.css-19f67a2-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #FFFFFF;
    color: var(--Primary600);
    font-weight: 700;
}

.MuiDataGrid-filterForm .MuiInputLabel-root {
    color: var(--Neutral800) !important;
    font-size: 14px !important;
    line-height: 16px !important;
    font-weight: 700 !important;
}

.MuiDataGrid-panel .MuiDataGrid-panelFooter {
    height: 52px;
    padding-left: 14px;
}

.MuiDataGrid-overlay { 
    font-size: 3rem;
    color: var(--Danger600);
    padding: 2rem;
    background: white;
}

.MuiDataGrid-filterForm .MuiFormControl-root .MuiInput-root .MuiNativeSelect-select {
    color: var(--Neutral800) !important;
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 400 !important;
    padding-left: 1rem;
    padding-right: 1rem;
}

.MuiDataGrid-filterForm .MuiFormControl-root .MuiFormControl-root .MuiInput-root .MuiInput-input {
    color: var(--Neutral800) !important;
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 400 !important;
    padding-left: 1rem;
    padding-right: 1rem;
}

.MuiDataGrid-panel .MuiDataGrid-panelFooter .MuiButton-text {
    padding: 0 !important;
    background-color: transparent !important;
    color: var(--Primary600) !important;
    font-size: 12px !important;
    line-height: 16px !important;
    font-weight: 700 !important;
}

.MuiDataGrid-panel .MuiPaper-root .MuiDataGrid-panelWrapper .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root .MuiTextField-root .MuiInput-root::after {
    border-bottom: 2px solid var(--Primary600);
}

.MuiDataGrid-panel .MuiPaper-root .MuiDataGrid-panelWrapper .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root .MuiInput-root::after {
    border-bottom: 2px solid var(--Primary600);
}

.MuiTableContainer-root>thead>tr>th {
    border-bottom: solid 0.5px #FAFAFA;
    background: #FFFFFF !important;
    color: var(--Neutral800);
    font-weight: 500 !important;
    font-size: 1.2rem;
    line-height: 2.5px;
    padding: 1rem 1rem !important;
    height: 4.8rem;
    box-shadow: inset -1px 0px 0px #F4F4F4 !important;
    overflow-wrap: break-word;
}

@media (min-width: 600px) {
    .MuiTablePagination-toolbar {
        /* height: 35px !important; */
        min-height: 35px !important;
        /* max-height: 35px !important; */
        padding-right: 2px;
    }
}

.MuiDataGrid-row:nth-child(odd) {
    background-color: #FFF;
    box-shadow: inset -1px 0px 0px #F4F4F4;
    height: 4.8rem !important;
}

.MuiTableHead-root>tr>th {
    background-color: #FFFFFF !important;
}

.MuiDataGrid-row:nth-child(even) {
    background-color: #FFFFFF;
    box-shadow: inset -1px 0px 0px #F4F4F4 !important;
}

.css-1wg7dj6-MuiDataGrid-row.Mui-selected {
    background-color: var(--Primary600);
}

.css-1wg7dj6-MuiDataGrid-row:hover {
    background-color: #62788C1A;
}

.css-1wg7dj6-MuiDataGrid-row.Mui-selected:hover {
    background-color: #85B4401A;
}


.DataGrid-marked-row--Section, .DataGrid-marked-row--Formula {
    border-top:1px solid #C0C0CF
}

.tableContainer {
    background: #fff;
    padding: 1rem 2rem 0 2.6rem;
    overflow-x: auto;
    height: calc(100vh - 145px);
    min-width: 115.8rem;
}

.tableContainer__SettingsModule {
    background: #fff;
    padding: 2rem 2rem 0 2.6rem;
    overflow-x: auto;
    height: calc(100vh - 265px);
    min-width: 115.8rem;
}

.tableContainer.filterExtended {
    background: var(--Neutral170);
    padding: 0.5rem 2rem 0 2.6rem;
    overflow-x: auto;
    height: calc(100vh - 416px);
    min-width: 115.8rem;
}
.tableContainer.filterExtended.sideBarExtended {
    background: var(--Neutral170);
    padding: 0.5rem 2rem 0 2.6rem;
    overflow-x: auto;
    height: calc(100vh - 416px);
    min-width: 115.8rem;
}

@media only screen and (max-width: 1817px) {
    .tableContainer.filterExtended {
        background: var(--Neutral170);
        padding: 0.5rem 2rem 0 2.6rem;
        overflow-x: auto;
        height: calc(100vh - 416px);
        min-width: 115.8rem;
    }
    /* .tableContainer.filterExtended.sideBarExtended {
        background: var(--Neutral170);
        padding: 0.5rem 2rem 0 2.6rem;
        overflow-x: auto;
        height: calc(100vh - 385px);
        min-width: 115.8rem;
    } */
}
@media only screen and (max-width: 1477px) {
    .tableContainer.filterExtended {
        background: var(--Neutral170);
        padding: 0.5rem 2rem 0 2.6rem;
        overflow-x: auto;
        height: calc(100vh - 416px);
        min-width: 115.8rem;
    }
    .tableContainer.filterExtended.sideBarExtended {
        background: var(--Neutral170);
        padding: 0.5rem 2rem 0 2.6rem;
        overflow-x: auto;
        height: calc(100vh - 416px);
        min-width: 115.8rem;
    }

}
@media only screen and (max-width: 1292px) {

    .tableContainer.filterExtended.sideBarExtended  {
        background: var(--Neutral170);
        padding: 0.5rem 2rem 0 2.6rem;
        overflow-x: auto;
        height: calc(100vh - 570px);
        min-width: 115.8rem;
    }

}
@media only screen and (max-width: 1137px) {

    .tableContainer.filterExtended{
        background: var(--Neutral170);
        padding: 0.5rem 2rem 0 2.6rem;
        overflow-x: auto;
        height: calc(100vh - 570px);
        min-width: 115.8rem;
    }

}


.tableContainer>.MuiPaper-elevation {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 6px;
    box-shadow: none;
    width: 100%;
    min-width: 120rem;
    margin-left: 0;
}

.table--controlsContainer {
    display: flex;
    background-color: #fff;
    padding: 1rem 1rem 0 2.6rem;
    gap: 1rem;

}


.MuiCheckbox-colorSecondary.Mui-checked {
    color: var(--Primary600) !important;
}

.no-results-container { 
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-results-container span {
    width: 346px;
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 40px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #BCBCBC;
    margin-left: 3.5rem;
}


/* Pagination */

.css-1cccqvr:focus {
    background-color: transparent !important;
    border-radius: 0px;
}

.Mui-disabled {
    background-color: transparent;
    color: rgba(129, 177, 65, 0.25) !important;
}

.MuiSelect-nativeInput {
    font-size: 1rem;
}

.css-194a1fa-MuiSelect-select-MuiInputBase-input:focus {
    background-color: transparent;
}


/* .css-isett9-MuiTablePagination-root .MuiTablePagination-selectLabel {
    margin-bottom: 4px;
} */


/* .MuiTablePagination-input {
    transform: translateX(20rem) !important;
    margin-left: 0.5rem !important;
    padding: 4px 12px 4px 16px;
    width: 51px;
    height: 30px;
    border: 1px solid #E1E4EB;
    box-sizing: border-box;
    border-radius: 4px;
} */


/* .MuiTablePagination-input div {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    align-items: center;
    color: var(--Neutral800);
    flex: none;
    transform: translateX(-2.5rem) !important;
} */


/* .css-23m3oi-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem.Mui-selected,
.css-23m3oi-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem.Mui-selected:hover {
    background-color: var(--Primary600)1A;
}

.css-23m3oi-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem:hover {
    background-color: #62788C1A;
}

.pagination-list-background {
    background-color: transparent !important;
} */

.iconButtonContainer {
    visibility: visible;
    width: auto;
}