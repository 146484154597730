/* style={{ display: 'flex', flexDirection: 'column', paddingTop: '20px', justifyContent: 'center', alignItems: 'center' }} */

.ComingSoon__Container{
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    justify-content: center;
    align-items: center;
    margin-top: 10rem;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    #f6f6f9;
}

.large-bold{
    /* width: 482px; */
    left: 490px;
    top: 56.02%;
    bottom: 36%;
    margin-top:4.1rem;
    font-style: normal;
    font-weight: bold;
    font-size: 33px;
    line-height: 65px;
    /* identical to box height, or 197% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #32324D;
}

@font-face {
    font-family: "SF Pro Text";
    font-weight: 400;
    src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
  }

.med-light{
    width: 590px;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    margin-top: 1rem;
    /* identical to box height, or 139% */

    /* display: flex; */
    align-items: center;
    text-align: center;

    color: var(--Neutral700);
}

.help-link, .help-link:hover{
    cursor: pointer;
    color: var(--Primary600);
    text-decoration-line: underline;
    text-decoration-style: solid;
}