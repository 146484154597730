.QA__Container {
    /* padding:2rem; */
    border-right: 1px solid var(--Neutral150);
    border-bottom: 1px solid var(--Neutral150);
    border-left: 1px solid var(--Neutral150);
}

.QA__Container__Comments {
    padding: 1rem 2rem;
    overflow-y: auto;
}

.ClientPayrollCard__Container {
    position: absolute;
    height: 100%;
    top:0rem;
    border-radius: 4px;
    background-color: white;
}

.ClientPayrollCard__Container span:first-child {
    font-size: 2.2rem;
    display: block;
}

.ClientPayrollCard__Container span:nth-child(2) {
    position: relative;
    font-size: 1.8rem;
}

.flex-row-space-between{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .RequestRevision__Content__Container{
    padding: 1.6rem;
  }

  .ClientPayrollCard__Container img {
    transform: translateY(-22rem);
    position: absolute;
  }

  .ClientPayrollCard__Container__Header__FileLink a{
    display: flex;
    color: none !important;
    font-size: 1.4rem;
    gap:0.5rem;
    align-items: center;
    cursor:pointer;
  }

  .ClientPayrollCard__Container__Header__FileLink a div{
    display:inline-block;
    color: none !important;
    font-size: 1.4rem;
    width: 12rem;
  }

  .PayrollCard__Container__Header__FileLink a div{
    display:inline-block;
    color: none !important;
    font-size: 1.4rem;
    width: 12rem;
  }


  .ClientPayrollCard__Container__Header div {
    font-size: 3.2rem;
  }

  .ClientPayrollCard__Container__Header--CenteredTitle div {
    font-size: 1.5rem;
  }
  
  .ClientPayrollCard__Container__Header__FileLink {
  margin-left: auto;
  /* position:absolute; */
  right: 20px;
  color: var(--Neutral600);
  display: flex;
  align-items: flex-start;
  gap:0.5rem;
  margin-right: 2rem;
  }

  .PayrollCard__Container__Header__FileLink {
    margin-left: auto;
    /* position:absolute; */
    right: 20px;
    color: var(--Neutral600);
    display: flex;
    align-items: flex-start;
    gap:0.5rem;
    margin-right: 2rem;
    }

  .ClientPayrollCard__Container__Header__Email {
    margin-right: auto;
    margin-left:2rem;
  }

  .PayrollCard__Container__Header__Email {
    margin-right: auto;
    margin-left:2rem;
  }