.side-nav {
    height: 100vh;
    top: 0px;
    left: 0px;
    background-color: var(--Neutral0);
    overflow: hidden;
    margin: 0px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.01em;
    padding: 0px 0px 0px 0px;
    overflow: visible;
    border-right: 1px solid var(--Neutral150);
    width: 100%;
    display: flex;
    flex-direction: column;
}

.aother .MuiChip-root {
    font-family: "SF Pro Text", Poppins !important;
    border-radius: 0.4rem !important;
    height: 3rem !important;
    background-color: var(--Neutral100) !important;
}

.MuiChip-label {
    color: var(--Neutral600) !important;
    font-size: 1.3rem !important;
    line-height: 2rem !important;
    padding-right: 0 !important;
    text-overflow: unset !important;
    font-weight: 700 !important;
}

.MuiChip-icon {
    color: #616161;
    margin-left: 0px !important;
    margin-right: -4px !important;
}

.side-nav__header {
    height: 5.1rem;
    display: flex;
    padding-left: 2.2rem;
    gap: 2rem;
    align-items: center;
    border-bottom: 1px solid var(--Neutral150);
    width: 100%;
}

.side-nav__header--expanded{
    display: flex;
    gap: 2rem;
    align-items: center;
    width: 100%;
}

.side-nav__footer {
    height: 5.1rem;
    display: flex;
    padding-left: 0.2rem;
    align-items: center;
    border-top: 1px solid var(--Neutral150);
    width: 100%;
    margin-top: auto;
    justify-content: center;
}

.side-nav__footer span {
    margin-left: 1rem;
}

.nav-header-logo {
    width: 15rem !important;
    min-width: 20px;
    height: 20px;
}

.side-nav__listContainer {
    padding: 1rem 1.2rem 2rem 1.2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content:flex-start;
    max-height: calc(100vh - 10rem);
    overflow-y: auto;
}

.side-nav__listContainer>div {
    text-decoration: none;
    transition: 0.3s;
    display: flex;
    padding: 0.925rem 1.32rem;
    cursor: pointer;
    width: 100%;
    justify-content: flex-start;
}

.SideBar__Container.collapsed .MuiChip-root{
    padding-left: 1rem !important;
}

.SideBar__Container.collapsed .side-nav__listContainer>div{
    display: flex;
    align-items: center;
    padding-right: 0;
    padding-left: 0;
    justify-content: center;
}

.side-nav__listContainer div span {
    -webkit-transition: 0.3s;
    transition: 0.3s;
    color: var(--Neutral600) !important;
    word-wrap: break-word;
}

.side-nav__listContainer div svg {
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-right: 15.33px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-self: center;
    fill: var(--Neutral500);
    color: var(--Neutral500);
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.side-nav__listContainer div svg path{
    fill:var(--Neutral500);
}

.side-nav__listContainer div svg g {
    fill: var(--Neutral500);
}

.SideBar__Container.collapsed .side-nav__listContainer div svg {
    margin-right: 0;
}

.side-nav__listContainer div:hover:not(.active) {
    opacity: 0.6;
}

.side-nav__listContainer div.active {
    background: var(--Primary100) !important;
}

.side-nav__listContainer div span.active {
    color: var(--Primary600) !important;
}


.side-nav__listContainer div svg.active g {
    fill: var(--Primary600);
}

.side-nav__listContainer div svg.active {
    fill: var(--Primary600);
}

.side-nav__listContainer div svg.active path {
    fill: var(--Primary600);
}

.side-nav__SectionHeader {
    display: flex;
    justify-content: space-between !important;
    width: 100%;
    align-items: center;
}

.side-nav__SectionHeader  svg {
    height: 0.4rem !important;
    width: 0.4rem !important;
    margin-right: 0rem !important;
    min-width: 0.6rem !important;
}

.ActiveHeader {
    color: var(--Primary600);
}

.ActiveHeader svg path{
    fill :var(--Primary600) !important;
}

.side-nav__listContainer button svg path {
    fill: var(--Neutral500);;
}
