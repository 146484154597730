:root {
  --page-margin-right: 2rem;
  --sideNav-width: 18rem;
  --table-margin-left: 1.1rem;
  --table-margin-top: 1.1rem;


  --Neutral900: #212134;
  --Neutral800: #32324d;
  --Neutral700: #4a4a6a;
  --Neutral600: #666687;
  --Neutral500: #8e8ea9;
  --Neutral400: #a5a5ba;
  --Neutral300: #c0c0cf;
  --Neutral200: #dcdce4;
  --Neutral170: #F5F5F5;
  --Neutral150: #eaeaef;
  --Neutral100: #f6f6f9;
  --Neutral50: #DADADA;
  --Neutral0: #ffffff;

  --Primary700: #6c9334;
  --Primary600: #85b440;
  --Primary500: rgba(133, 180, 64, 0.75);
  --Primary200: #85b4401a;
  --Primary100: #85b4400d;

  --Danger700: #b72b1a;
  --Danger600: #d02b20;
  --Danger500: #ee5e52;
  --Danger200: #f5c0b8;
  --Danger100: #fcecea;

  --Success700: #2f6846;
  --Success600: #328048;
  --Success500: #5cb176;
  --Success200: #c6f0c2;
  --Success100: #eafbe7;
  --Success050: #f1fbef;

  --Warning600: #d9822f;
  --Warning200: #fae7b9;
  --Warning100: #fdf4dc;

  --Secondary700: #006096;
  --Secondary600: #0c75af;
  --Secondary200: #b8e1ff;
  --Secondary100: #eaf5ff;

  --Alternative600: #9736e8;
  --Alternative200: #e0c1f4;
  --Alternative100: #f6ecfc;

  --BoxShadow: 0px 2px 15px 0px #2121341a;
  --BoxShadow-ReactDate: 0px 2px 15px 0px rgba(33, 33, 52, 0.1);

  --toastify-color-success: var(--Primary600);
  --toastify-color-error: #e24c4b;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* reset margins */
body,
h1,
h2,
h3,
h4,
h5,
p,
figure,
picture {
  margin: 0;
}

/* set up the body */
body {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  font-family: "SF Pro Text", Poppins, sans-serif !important;
  line-height: 1.5;
  scrollbar-color: var(--Neutral150) white;
  scrollbar-width: thin;
}

.react-datepicker {
  font-family: "SF Pro Text", Poppins, sans-serif !important;
}

body::-webkit-scrollbar-track {
  background: var(--Neutral150);
}
body::-webkit-scrollbar-thumb {
  border: 1px solid var(--Neutral150);
}

@font-face {
  font-family: "SF Pro Text";
  font-weight: 400;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
}

@font-face {
  font-family: "SF Pro Text";
  font-weight: 700;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-bold-webfont.woff");
}
@font-face {
  font-family: "SF Pro Text";
  font-weight: 600;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-semibold-webfont.woff");
}
@font-face {
  font-family: "SF Pro Text";
  font-weight: 300;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-light-webfont.woff");
}

/* make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* make forms easier to work with */
input,
button,
textarea,
select {
  font: inherit;
  border: none;
  border-radius: 0.4rem;
  line-height: 1.6rem;
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
}

textarea {
  resize: none;
}



input:focus-visible,input:focus, textarea:focus-visible, textarea:hover, select:focus-visible, input:hover {
  outline: 1px solid var(--Primary600);
  outline-offset: 0;
}

.btn--primary:focus-visible, .btn--primary:hover, .btn--M:hover {
  outline: none;
}


input:disabled:focus-visible, textarea:disabled:focus-visible, select:disabled:focus-visible, input:disabled:hover {
  outline: 2px solid var(--Neutral200);
}

input[type="checkbox"] { /* change "blue" browser chrome to yellow */
  filter: invert(0%) hue-rotate(223deg) brightness(1.4);
}

.Mui-checked{
  color: var(--Primary600) !important;
}

.MuiSwitch-switchBase.Mui-checked {
  color: white !important;
}

input[type="checkbox"]:focus-visible, input[type="checkbox"]:hover{
  filter: none;
}

.react-datepicker__input-container > input:focus-visible {
  border: none !important;
}

.full-height{
  height: auto !important;
}

/* remove animations for people who turned them off */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

html {
  font-size: 62.5%;
  background-color: #f7f7f7;
  height: 100vh;
}

main {
  margin: 0 auto;
  width: 100%;
}

section#text-container > div > p {
  font: 1.2em "segoe ui", arial, sans-serif;
}

button:focus {
  outline: none;
}

@media (min-width: 1000px) {
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 60%;
    max-width: 50%;
  }
}

footer > p {
  font: 1em "segoe ui", arial, sans-serif;
}

iframe {
  border-radius: 1rem;
  border-style: hidden;
}

.AppContainer {
  display: grid;
  transition: 0.3s;
  height: 100vh;
  grid-template-columns: 221px calc(100% - 219px);
  grid-template-rows: 100%;
  overflow: hidden;
  grid-template-areas:
    "sidebar mainContent";
    
}

.AppContainer.collapsed {
  transition: 0.3s;
  display: grid;
  height: 100vh;
  grid-template-columns: 64px calc(100% - 64px);
  grid-template-rows: 100%;
  overflow: hidden;
  grid-template-areas:
    "sidebar mainContent";
}

.SideBar__Container {
  grid-area: sidebar;
  position: fixed;
  width:21.6rem;
}

.SideBar__Container.collapsed {
  grid-area: sidebar;
  position: fixed;
  overflow: hidden;
  width: 64px;
}

.sidebar-toggle-btn {
  cursor: pointer;
}

.bi-report-wrapper {
  grid-area: mainContent;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  /* background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    #f6f6f9; */
  margin-left: -5px;
}

.bi-report-wrapper.collapsed {
  grid-area: mainContent;
  width: 100%;
  overflow: hidden;
  /* background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    #f6f6f9; */
  margin-left: 0px;
}

.bi-report-wrapper.no-modules {
  left: 0;
  width: 100%;
}

.Toastify__toast--success {
  border-left: 3.5px solid var(--toastify-color-success);
}

.Toastify__toast--error {
  border-left: 3.5px solid var(--toastify-color-error);
  background-color: var(--Danger100) !important;
  border: 1px solid var(--Danger200) !important;
}

.data-tooltip {
  font-size: 11px;
  font-weight: 400;
  line-height: 20px;
  min-height: 40px;
  max-width: 220px;
}

.flex {
  display: flex;
  gap: var(--gap, 1rem);
}

.grid {
  display: grid;
  gap: var(--gap, 1rem);
}

.container {
  padding: 0 2em;
  max-width: 80rem;
  margin-inline: auto;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.MuiDialog-paper {
  width: auto;
  background-color: white !important;
  color: var(--Neutral800) !important;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 0.5rem;
  box-shadow: var(--BoxShadow);
  margin: 32px;
  position: relative;
  overflow: auto !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 1rem;
  /* max-height: 71.9rem; */
  margin-bottom: 3.5rem;
  height: auto;
  width: auto;
  /* max-width: 80rem !important; */
  
}

.MuiDialogContent-root {
  scrollbar-width: thin;
}



.alert-dialog-alert > .MuiDialog-container > .MuiDialog-paper >.MuiDialogTitle-root{
  height: auto;
  padding: 1.6rem 1.6rem !important;
  background-color: white;
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--Neutral800);
  font-style: normal;
  font-weight: 400;
  display:flex;
  align-items: center;
  gap:1rem;
}

.alert-dialog-user-form > .MuiDialog-container {
  align-items: flex-start !important;
}
.alert-dialog-duplicate-form > .MuiDialog-container {
  align-items: center;
}

.alert-dialog-user-form > .MuiDialog-container > .MuiDialog-paper >.MuiDialogTitle-root, .alert-dialog-duplicate-form > .MuiDialog-container > .MuiDialog-paper >.MuiDialogTitle-root{
  height: auto;
  padding: 2.4rem 2rem !important;
  background-color: var(--Neutral100);
  font-size: 1.7rem;
  line-height: 1.6rem;
  color: var(--Neutral800);
  font-style: normal;
  font-weight: 700;
  display:flex;
  align-items: center;
  justify-content: space-between;
}

.alert-dialog-task-form > .MuiDialog-container > .MuiDialog-paper >.MuiDialogTitle-root{
  height: auto;
  padding: 1.6rem 2rem !important;
  background-color: var(--Neutral100);
  font-size: 1.7rem;
  line-height: 1.6rem;
  color: var(--Neutral800);
  font-style: normal;
  font-weight: 700;
  display:flex;
  align-items: center;
  justify-content:flex-end;
}

.alert-dialog-alert > .MuiDialog-container > .MuiDialog-paper > .MuiDialogActions-root {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  padding: 1.7rem 1.6rem;
  justify-content: space-between;
  align-items: center;
  gap:0.8rem;
}

.alert-dialog-user-form > .MuiDialog-container > .MuiDialog-paper > .MuiDialogActions-root, .alert-dialog-duplicate-form > .MuiDialog-container > .MuiDialog-paper > .MuiDialogActions-root {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  padding: 1.7rem 1.6rem;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--Neutral100);
  font-size: 1.2rem;
  gap:2.1rem;
}

/* reset default behavior */

.replicate-input{
  display: flex;
  gap: 10px;
  align-items: flex-end;
  margin-left: auto;
  flex-direction: column;
}

.replicate-input > div {
  display: flex;
  gap: 10px;
  align-items: baseline;
}

.replicate-input input {
  padding: 1rem 0rem;
  text-align: center;
  color: var(--Neutral800);
  font-weight: 700;
  font-size: 1.2rem;
  border-radius: 0.4rem;
  border: 1px solid var(--Neutral150);
  line-height: 1.6rem;
  width:5.1rem;
  
}

.btn--primary {
  color: var(--Neutral0) !important;
  background-color: var(--Primary600) !important;
  font-weight: 600 !important;
  gap:0.8rem !important;
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn--primary:hover {
  background-color: var(--Primary500);
}

.btn--primary:active {
  background-color: var(--Primary700);
}

.btn--primary:disabled,
.btn--primary--disabled {
  background: var(--Neutral150);
  color: var(--Neutral600);
  border: none;
  border: 1px solid var(--Neutral200);
  opacity: 0.5;
  cursor:not-allowed;
}

.btn--primary:disabled span {
  padding: 1rem 1.6rem;
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-align: center;
  color: var(--Neutral600);
  opacity: 50%;
}

.btn--secondary {
  color: var(--Primary600) !important;
  background-color: var(--Primary100) !important;
  border: 1px solid var(--Primary200) !important;
  border-radius: 0.4rem !important;
  font-weight: 700 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap:0.2rem !important;
  white-space: nowrap;
  cursor: pointer;
}

.btn--secondary--completed {
  color: var(--Success500) !important;
  background-color: var(--Success100) !important;
  border: 1px solid var(--Success500) !important;
  border-radius: 0.4rem !important;
  font-weight: 700 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap:0.8rem !important;
  white-space: nowrap;
  cursor: pointer;
}

.btn--secondary span,  .btn--secondary-danger span{
  line-height: 1.1rem;
}

.btn--tertiary {
  color: var(--Neutral800) !important;
  background-color: var(--Neutral0) !important;
  border: 1px solid var(--Neutral200) !important;
  /* font-weight: 600 !important; */
  text-transform:none !important;
  border-radius: 0.4rem;
  cursor: pointer;
}

.btn--tertiary:hover {
  background: var(--Neutral100);
}

.btn--primary-danger {
  color: var(--Neutral0);
  background-color: var(--Danger600);
  font-weight: 700;
  cursor: pointer;
}

.btn--secondary-danger {
  color: var(--Danger600) !important;
  background-color: var(--Danger100) !important;
  border: 1px solid var(--Danger200) !important;
  font-weight: 600 !important;
  text-transform:none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap:0.2rem !important;
  cursor: pointer;
}

.btn--secondary-danger svg path {
  fill:var(--Danger600) !important;
}

.flex-row--S {
  display: flex;
  gap:0.8rem;
  align-items: center;
}

.btn--fullWidth {
  width: 100%;
  height: 100%;
}

.btn--80Width {
  width: 80%;
}

.btn--S {
  padding: 0.5rem 1rem !important;
  font-size: 1.2rem !important;
  /* font-weight: 700 !important; */
  height: 3.2rem;
}

.btn--S--square {
  padding: 1rem 1rem !important;
  font-size: 1.1rem !important;
  font-weight: 700 !important;
}

.btn--XS {
  padding: 0.5rem 1.6rem;
}

.btn--XXXS--centered {
  padding: 0.3rem 0.3rem;
  font-size: 1rem;
}

.btn--XXS--centered {
  width: 3.2rem;
  height: 3.2rem;
  padding: 0.6rem 0.6rem;
}

.btn--XXXS--centered > svg {
  font-size: 1rem;
}


.btn--S--centered {
  padding: 0.7rem 0.7rem;

}

.btn--M--centered {
  display: flex;
  align-items: center;
  justify-content: center;
  gap:0.8rem;
  padding:0.6rem;
}

.btn--M {
  padding: 1rem 1.6rem !important;
  font-size: 1.4rem !important;
}

.btn--L {
  padding: 1.2rem 1.6rem;
}

.vertical-divider {
  color: var(--Neutral200) !important;
  font-weight: 500 !important;
  font-size: 1.3rem;
}

.btn--med--green {
  padding: 1rem 1.6rem;
  background: var(--Primary600);
  border: none;
  color: var(--Neutral0);
}

.btn--med--green--disabled {
  padding: 1rem 1.6rem;
  background: var(--Neutral150);
  color: var(--Neutral600);
  border: none;
  border: 1px solid var(--Neutral200);
}

.btn--med--green--disabled span {
  padding: 1rem 1.6rem;
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-align: center;
  color: var(--Neutral600);
  opacity: 50%;
}

.btn--med--green span {
  width: 37.9px;
  height: 18px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}

.Alerts {
  width: 50rem;
  border-radius: 0.4rem;
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--Neutral800);
}

.Alerts--S,
.Alerts--S--link {
  padding: 2rem 2.4rem 2rem 2rem;
}
.Alerts--L {
  padding: 2rem 2.4rem 2.2rem 2rem;
}
.Alerts--L--link {
  padding: 2rem 2.4rem 2.2rem 2rem;
}

.Alerts__title {
  font-weight: 700;
  line-height: 1.6rem;
}

.Alerts__body {
  padding: 0.9rem 2.8rem 0.7rem 3.2rem;
}

.Alerts--information {
  background-color: var(--Neutral100);
  border: 1px solid var(--Neutral150);
  box-shadow: var(--BoxShadow);
}
.Alerts--success {
  background-color: var(--Success100);
  border: 1px solid var(--Success200);
  box-shadow: var(--BoxShadow);
}
.Alerts--warning {
  background-color: var(--Danger100);
  border: 1px solid var(--Danger200);
  box-shadow: var(--BoxShadow);
}

/*-------------------------------------- DATE PICKER -------------------------*/

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text,
.react-datepicker__year-text {
  width: 8.4rem !important;
  height: 3.2rem !important;
  font-size: 1.2rem !important;
  border: none;
  border-radius: 0.4rem;
  vertical-align: middle !important;
  line-height: 3.2rem !important;
  margin: 0 !important;
}

.react-datepicker__month {
  padding-right: 1.6rem !important;
  padding-left: 1.6rem !important;
}

.react-datepicker__month-container,
.react-datepicker__year--container,
.react-datepicker__quarter--container {
  height: auto !important;
  width: 100%;
  box-sizing: border-box;
  box-shadow: var(--BoxShadow-ReactDate);
  border-radius: 0.4rem !important;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.react-datepicker__quarter--container {
  padding-bottom: 0rem !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__quarter--selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  background-color: var(--Primary100) !important;
  color: var(--Primary600) !important;
  font-weight: 700;
}

.react-datepicker__input-container > input {
  width: 100%;
  height: 4rem;
  box-sizing: border-box;
  border-radius: 0.4rem !important;
  padding: 1rem 1rem 1rem 1rem !important;
  color: var(--Neutral800);
  font-size: 1.4rem;
  border: 0.5px solid rgba(98, 120, 140, 0.4);
}

.react-datepicker-ignore-onclickoutside {
  width: 28.4rem;
  height: 3.7rem;
  border: 0.5px solid rgba(98, 120, 140, 0.4);
  box-sizing: border-box;
  border-radius: 1rem !important;
  padding: 2rem 1rem;
}

.react-datepicker__year-read-view--down-arrow {
  height: 10px !important;
}

.react-datepicker__year-read-view--down-arrow {
  top: 2px;
}

.react-datepicker-popper[data-placement^="bottom"] {
  border-color: #fff;
  z-index: 2;
}

.react-datepicker {
  height: calc(100% - 20px);
  border-radius: 0.4rem !important;
  border: 0px #fff !important;
  font-family: "SF Pro Text" !important;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 1rem !important;
  margin-bottom: 1.2rem !important;
  border: none !important;
  border-bottom: none !important;
}

.react-datepicker__header {
  font-size: 1.4rem;
  color: var(--Neutral800);
  text-align: center;
  background-color: white !important;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 1rem !important;
  padding: 8px 0;
  position: relative;
  margin-bottom: 2rem !important;
}

.react-datepicker__header__dropdown{
  margin: 3px 0px;
}

.react-datepicker__year-dropdown {
width: 30% !important;
}

.react-datepicker-year-header {
  font-size: 1.4rem !important;
  color: var(--Neutral800) !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 21px !important;
  margin-bottom: 2rem !important;
}

/* NAVIGATION */

.react-datepicker__navigation {
  top: 1rem !important;
  transform: translateX(-2.3rem);
}

.react-datepicker__navigation--previous {
  left: 40px !important;
}

.react-datepicker__navigation--next {
  right: 52px !important;
  transform: translateX(3.6rem);
}

.react-datepicker__navigation--next:focus {
  outline: none !important;
}

.react-datepicker__navigation--previous:focus {
  outline: none !important;
}

.react-datepicker__navigation:hover *::before {
  border-color: #333333 !important;
}

.react-datepicker-wrapper {
  vertical-align: middle !important;
  border-radius: 10px;
  box-sizing: border-box;
  height: 100%;
  width: 100% !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  display: none;
}

.react-datepicker-ignore-onclickoutside::after {
  content: FaCalendarAlt;
}

/* YEAR PICKER */
.react-datepicker__year {
  margin: 1.2rem;
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 0 !important;
}

.react-datepicker__year-wrapper {
  gap: 0.3rem;
  width: 26rem;
  max-width: 30rem !important;
}

/* QUARTER PICKER */
.react-datepicker__quarter--selected {
  background-color: var(--Primary600);
}

.react-datepicker__quarterPicker {
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-datepicker__quarter-wrapper {
  display: grid;
  grid-template-columns: 90px 90px;
  grid-template-rows: 34px 34px;
  column-gap: 5px;
  row-gap: 0px;
  padding-left: 6px;
  padding-top: 1rem;
}

.MuiTablePagination-displayedRows {
  margin-bottom: 0;
  transform: translateX(-20rem);
  font-size: 1.4rem;
  padding: 4px 12px 4px 12px !important;
  border: 1px solid #e1e4eb;
  box-sizing: border-box;
  border-radius: 4px;
  width: 14.5rem;
  text-align: center;
  z-index: 10;
}

.MuiTablePagination-actions button:nth-of-type(1) {
  transform: translateX(-18rem);
  color: var(--Primary600);
  z-index: inherit;
}

.MuiTablePagination-actions button:nth-of-type(2) {
  transform: translateX(-1.4rem);
  color: var(--Primary600);
  z-index: inherit;
}

.MuiDataGrid-footerContainer {
  justify-content: flex-end;
  height: 6.1rem;
}

.MuiTablePagination-root {
  height: 61px !important;
  padding: 0 !important;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.MuiTablePagination-root .MuiTablePagination-toolbar {
  padding-left: 50%;
  width: 100%;
  flex-direction: row-reverse;
  align-items: center;
  height: auto;
}

.css-12ovgqq-MuiInputBase-root-MuiTablePagination-select {
  transform: translateX(20rem);
}

@media (max-width: 1162px) {
  .css-12ovgqq-MuiInputBase-root-MuiTablePagination-select {
    transform: translateX(10rem);
  }

  .MuiTablePagination-selectLabel {
    transform: translateX(-10rem);
  }
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 3.1rem !important;
  line-height: 1.6rem !important;
  margin: 0.4rem 0.4rem !important;
  color: var(--Neutral900);
}

.react-datepicker__week {
  font-size: 1.2rem;
}

.react-datepicker__current-month {
  font-size: 1.4rem !important;
}

.react-datepicker__year-dropdown-container .react-datepicker__year-dropdown, 
.react-datepicker__year-dropdown-container .react-datepicker__month-dropdown, 
.react-datepicker__year-dropdown-container .react-datepicker__month-year-dropdown {
  background-color: #fff;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.4rem;
  border: none;
  box-shadow: var(--BoxShadow);
}

.react-datepicker__year-dropdown .react-datepicker__year-option:hover, 
.react-datepicker__year-dropdown .react-datepicker__month-option:hover, 
.react-datepicker__year-dropdown .react-datepicker__month-year-option:hover {
  background-color: #fff;
  color: var(--Primary600);
}

.react-datepicker__year-option--selected, .react-datepicker__month-option--selected, .react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
  color: #85b440 !important;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
    transform: rotate(135deg);
    right: -17px !important;
    top: 2px !important;
}

.Toastify__toast--success {
  border-left: none !important;
}
.Toastify__toast-theme--light {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast {
  position: relative;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  top: 1rem;
  padding: 2rem 2.4rem 1.9rem 2rem;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* max-height: 12.9rem; */
  overflow: hidden;
  cursor: pointer;
  direction: ltr;
  background-color: var(--Success100);
}

.Toastify__toast-container {
  max-width: 50%;
  width: auto !important;
}

.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 6px;
  display: flex;
  align-items: center;
}

.Toastify__toast-icon {
  margin-bottom: 3.5rem;
  margin-right: 1.3rem;
}
.ToastBGWhite {
  background-color: var(--Neutral0);
}

.header-bi  div[class$="-container"] {
  width: 13rem;
  height: 33px !important;
}

.header-bi div[class$="-control"] {
  height: 100%;
  min-height: 100%;
  background: none !important;
}

.header-bi div[class$="-ValueContainer"], .header-bi div[class$="-ValueContainer"]:hover {
  height: 100%;
  background: none;
}

.onboarding__block--y div[class$="-control"]:hover,  .onboarding__block--y div[class$="-control"]:focus-visible {
  border: 2px solid var(--Primary600) !important;
  outline: none;
}


.bi-header__select  div[class$="-container"],.header-bi  div[class$="-container"]{
height: 3.3rem;
}

.bi-header__select div[class$="-container"]:hover, .header-bi div[class$="-container"]:hover {
  background: white !important;
}

.bi-header__select .css-1lysxei,  .bi-header__select .css-1lysxei:hover, .header-bi .css-1lysxei,  .header-bi .css-1lysxei:hover{ 
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  background: none !important;
}

.bi-header__select .css-1lysxei, .header-bi .css-1lysxei {
  margin-bottom: 7px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 2px 8px;
  -webkit-overflow-scrolling: touch;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  top: 0;
  font-family: SF Pro Text;
  font-size: 14px;
  border-radius: 4px;
  margin-left: auto;
  cursor: pointer;
  height: 40px;
  -webkit-transition: none;
  transition: none;
  border: none;
}


.bi-header__select div[class$="-singleValue"], .header-bi div[class$="-singleValue"] {
font-weight: 600 !important;
padding: 0.5rem 0rem;
}

div[class$="-indicatorContainer"] { 
  height: 33px;
}

.bi-header__select div[class$="-indicatorContainer"], .bi-header__select div[class$="-indicatorContainer"],
.header-bi div[class$="-indicatorContainer"], .header-bi div[class$="-indicatorContainer"] {
  transform: translateY(0px);
  padding: 4px 8px 4px 0px;
}

.bi-header__select div[class$="-ValueContainer"],.header-bi div[class$="-ValueContainer"] {
  height: 33px !important;
  margin-bottom: 7px;
}

div[class$="-MuiInputBase-root-MuiInput-root"]:before {
  border-bottom: none !important;
}



.bi-header__select div[class$="-control"],
 .bi-header__select div[class$="-container"], .header-bi div[class$="-container"] div[class$="-control"],
 .header-bi div[class$="-control"],
 .header-bi div[class$="-container"], .header-bi div[class$="-container"] div[class$="-control"]{
  height: 33px !important;
  min-height: 33px !important;
}

.header-bi div[class$="-container"] div[class$="-control"]:hover, .bi-header__select div[class$="-control"]:hover {
  border: 2px solid #85B440;
}

.bi-header__select div[class$="-control"] div, .header-bi div[class$="-control"] div {
  height: 33px !important;
  align-items: center;
}

.bi-header__select div[class$="-ValueContainer"]:hover, .header-bi div[class$="-ValueContainer"]:hover  {
background: none !important;
}

span[class$="MuiCheckbox-root"]{
  color: #85B440 !important;
}

.css-9eufwv {
  font-size: 45px;
}


/* Employee List Filter UI Styling */

/* value tag */
.MuiDataGrid-panel .css-16awh2u-MuiAutocomplete-root .MuiAutocomplete-tag {
  margin: 3px;
  width: auto !important;
  height: auto !important;
  padding: 0.3rem 1.1rem;
  background-color: var(--Primary100) !important;
  border: 1px solid var(--Primary200);
  
}

.MuiDataGrid-panel .MuiChip-label {
  padding-left: 0;
  color: var(--Primary600) !important;
}

.MuiDataGrid-panel .MuiChip-deleteIconSmall {
  margin-left: 0.8rem;
  padding-left: 0.8rem;
  margin-right: 0rem;
  color: var(--Primary600);
  border-left: 1px solid var(--Primary200);
  font-size: 22px;

}

.MuiDataGrid-panel .MuiChip-deleteIconSmall:hover {
  color: var(--Danger200);

}

.css-16awh2u-MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  width: 100%;
  min-width: 30px;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-16awh2u-MuiAutocomplete-root .MuiAutocomplete-inputRoot {
padding-right: 0;
}

/* employee list footer */
.MuiDataGrid-selectedRowCount {
  font-size: 8.76px;
}


.Title__Container {
  display: flex;
  align-self: center;
  justify-self: flex-start;

  align-items: center;
  margin-right: auto;
  gap: 1rem;
}


.MuiDataGrid-filterForm .MuiFormControl-root:nth-child(5) {
  width:270px;
}

.MuiAutocomplete-tag{
  padding: 5px;
}

.DatePickerCustomContainer {
  width: 100%;
  position: relative;
}

.DatePickerCustomContainer svg {
  position: absolute;
  top: 26%;
  z-index: 1;
  left: 1.2rem;
  color: var(--Neutral500);
  font-size: 1.6rem;
}

.react-datepicker__close-icon {
  right: 0.3rem !important;
}

.react-datepicker__close-icon::after {
  background-color: var(--Primary500) !important;
  font-size: 1.4rem !important;
}

.react-datepicker__month-wrapper {
  display: flex;
}

div[class$=-MuiInputBase-root-MuiOutlinedInput-root],.MuiOutlinedInput-notchedOutline {
  border-color: var(--Primary600);
}

div[class$=-MuiInputBase-root-MuiOutlinedInput-root].Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--Primary600) !important;
  border-width: 2px;
}

div[class$=-MuiInputBase-root-MuiOutlinedInput-root]:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--Primary600);
}

input[type="file"] {
}

.custom_file_upload {
  color: var(--Primary600);
  font-weight: 700;
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
  cursor: pointer;
}


div[class^=css-]:hover:not(.Mui-disabled)::before {
  border-bottom: none !important;
}

.AsanaPhoto{
  border-radius:50%;
}

.title__Milestone__Container {
  display: flex;
  gap: 1rem;
  margin-left: 1rem;
  align-items: center;
}

.title__Milestone__Container span {
  color: var(--Neutral800);
  font-size: 1.8rem;
  font-weight: 600;
}


.Black-Button svg path{
  fill: var(--Neutral800) !important;

}


.SharepointExplorer__Header {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding:1rem;
  font-size:1.6rem;
  line-height: 2.4rem;
  color: var(--Neutral900);

}

.SharepointExplorer__Item {
  display: flex;
  flex-direction: row;
  gap:0.5rem;
  align-items: center;
  cursor: pointer;
}

.SharepointExplorer__Item__Container.selected{
  background-color: var(--Primary200);
}

.SharepointExplorer__Item__Container{
  display: flex;
  align-items: center;
  background-color: none;
  width: 12.1rem;
  height: 12.1rem;
  flex-direction: column;
  cursor: pointer;
  gap: 0.4rem;
  padding:1rem;
}

.SharepointExplorer__Item__Label {
  text-align: center;
}

.AppError__Container{
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.AppError__Container .opps {
  margin-top: 3rem;
  font-size: 1.8rem;
  font-weight:600;
}

.AppError__Container .message {
  color: var(--Neutral600);
  font-size: medium;
  margin-bottom: 30rem;
}

.CompanyQBOConnect {
  width: 37rem;
}