/* style={{ display: 'flex', justifyContent: 'space-between', backgroundImage: `url(${backGround})`, backgroundSize: 'cover', height: '100vh', width: '100%', color: 'white' }} */

.Parent_Container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../static/img/invitation_background.jpg");
    background-size: cover;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.Parent_Container::before {
    content: "";
    position: absolute;
    width: 100vw;
    min-width: 1440px;
    height: 100vh;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    /* background: radial-gradient(farthest-corner at 14.34% -11.49%, rgba(56, 67, 77, 0.9) 0%, rgba(56, 67, 77, 0.8) 49.86%,  #161C24 100%, #38434D 100%); */
    background: radial-gradient( farthest-corner at -24.34% 20.49%, rgba(96, 102, 115, 0.9) 0%, rgba(40, 53, 67, 0.9) 58%, rgba(19, 20, 39, 1) 75.86%, rgb(5, 6, 11) 100%);
}

.Container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    z-index: 1;
}

.Container span {
    font-weight: normal;
    font-size: 18px;
    line-height: 45px;
    color: white;
}

.Input__Container {
    background-color: white;
    width: 48.3rem;
    height: 100%;
    color: black;
    padding: 2.6rem 2.8rem 2rem 2.8rem;
    border-radius: 1rem;
    height: auto;
    margin-bottom: 10rem;
}

.AuthPage__logo {
    transform: translateY(-14rem);
    height: 5rem;
}

.AuthPage__LeftSection div {
    transform: translateY(-15rem);
}

.AuthPage__Welcome div {
    font-weight: 400;
    font-size: 4.8rem;
    line-height: 6.3rem;
}

.AuthPage__LeftSection div h1 {
    font-weight: 700;
    font-size: 7.5em;
    line-height: 6.3rem;
}