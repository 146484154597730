.SubTask__EmailPreview__Body__Container__Banner__TopLine {
    height: 0.8rem;
    background-color: var(--Neutral900);
}

.SubTask__EmailPreview__Container {
    /* padding: 2rem 1.6rem; */
    overflow-y: auto;
    height: calc(100vh - 37rem);
    font-size: 1.4rem;
}

.SubTask__EmailPreview__Body__Container__Banner__Logo{
    padding:2.4rem;
}

.SubTask__EmailPreview__Body__MainTitle__Container {
    padding: 1.9rem 2.4rem 2.4rem 2.4rem;
    background: #FAFAFB;
    color: var(--Neutral900);
    display: flex;
    flex-direction: column;
}

.SubTask__EmailPreview__Body__MainTitle__MainContent__Title{
    color: var(--Neutral900);
    font-family: 'Poppings';
    font-weight: 700;
    font-size: 3.5rem;
    line-height: 4rem;
    letter-spacing: -0.02em;
    height: 5.5rem;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

}

.SubTask__EmailPreview__Body__MainTitle__MainContent__customFields{
    font-size: 1.6rem;
    line-height: 2.4rem;
}

.SubTask__EmailPreview__Body__MainTitle__MainContent__cycledates {
    font-size: 1.2rem;
    line-height: 2.4rem;
    color: #979dad;
}


.SubTask__EmailPreview__Body__MainTitle__MainContent__Title button {
    background: #128045;
    padding: 0.7rem;
    color: white;
    display: flex;
    align-items: center;
    gap:0.4rem;
}

.SubTask__EmailPreview__Body__MainTitle__MainContent__Title button img {
    border-radius: 50%;
}

.SubTask__EmailPreview__Body__MainTitle__Text__Container {
    padding: 2.4rem;
    line-height: 2.5rem;
    font-size: 1.5rem;
}


input:focus-visible, input:hover {
    outline:none;
}

.tags-input {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 40px;
    width: 480px;
    padding: 0 8px;
    border-radius: 6px;
    width:100%;
}

.tags-input button {
    background-color: transparent;
}

/* .tags-input:focus-within {
    border: 1px solid #0052cc;
} */

.tags-input input {
    flex: 1;
    border: none;
    height: 35px;
    font-size: 14px;
    padding: 4px 0 0 0;
}

.tags-input input:focus {
    outline: transparent;
}

#tags {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 4px 0 0 0;
}

.tag {
    width: auto;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--Primary600);
    padding: 0 8px;
    font-size: 14px;
    list-style: none;
    border-radius: 4px;
    margin: 0 4px 4px 0;
    background: var(--Primary100);
    font-weight: 600;
    border:1px solid var(--Primary200);
    gap:0.8rem;
}

#no-permission {
    background-color: var(--Danger100);
    border: 1px solid var(--Danger200);
    color: var(--Danger600);
}



#no-permission svg path {
    fill: var(--Danger600);
}

#no-app-asana-permission {
    background-color: var(--Warning100);
    border: 1px solid var(--Warning200);
    color: var(--Warning600);
}

#no-app-asana-permission svg path {
    fill: var(--Warning600);
}

.tag-close-icon {
    height: 15px;
    display: flex;
    align-items: center;
    border-left: 2px solid var(--Primary200);
    margin-left: 0px;
    padding-left: 4px;
}
.tag .tag-close-icon svg {
    display: block;
    width: 16px;
    height: 8px;
    line-height: 16px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    
    
    margin-right: 5px;
}

.tag-close-icon svg path{
    fill: var(--Primary600);
}

@media screen and (max-width: 567px) {
    .tags-input {
        width: calc(100vw - 32px);
    }
}