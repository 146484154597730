.tableContainer {
    height: calc(100vh - 205px)
}

.tableContainer__ReportModule {
    background: #fff;
    /* overflow-x: auto; */
    height: calc(100vh - 558px);
    min-width: 80rem;
}

.tableContainer__ReportModule__FilterOpen {
    background: #fff;
    /* overflow-x: auto; */
    height: calc(100vh - 631px);
    min-width: 80rem;
}

.tableContainer__ReportModule__Extend {
    background: #fff;
    /* overflow-x: auto; */
    height: calc(100vh - 432px);
    min-width: 80rem;
}


.table-manageUsers-controlsContainer {
    display: flex;
    background-color: var(--Neutral170);
    padding: 3rem 2rem 0 2.6rem;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.manage-users-selected {
    color: #32324D;
    font-weight: 700;
    /* font-size: 1.25rem; */
    line-height: 1;
}

.Header__Actuals::before {
    content: 'Actuals';
}

.Header__Projections::before {
    content: 'Projections';
}