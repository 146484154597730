
.Toast__Container span {
    font-weight: 600;
    font-size: 12px;
    line-height: 10px;
    
    /* identical to box height, or 83% */
    letter-spacing: 0.01em;
    
    color: var(--Neutral800);
}

.Toastify__spinner {
    color:red;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border: 2px solid;
    border-radius: 100%;
    border-color: rgba(98, 120, 140, 0.2);;
    border-right-color: var(--Primary600);
    animation: Toastify__spin 0.65s linear infinite;
}

.Toastify__progress-bar--info {
    background: var(--Primary600);
}