.UploadFile__Body__Container input[type="file"] {
    display: inline-block;
  }

.UploadFile__Body__Container {
    padding: 1.8rem 2rem;
    height: auto;
    width: 100%;
}

.UploadFile__Body__Container > .upload_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed #DCDCE4;
    padding-bottom: 2rem;
    height: auto;
}

.UploadFile__Body__Container__ExampleImage {
    border: 1px solid var(--Neutral200, #DCDCE4);
}

.action_btns_container {
display: flex;
justify-content: space-between;
width: 100%;
}

.UploadFile__Body__Container > div > svg {
margin-bottom: 1.4rem;
height: auto;
}

.UploadFile__Body__Container__ExampleImage__Header{
    width: 100%;
    text-align: center;
    color: var(--Neutral500, #8E8EA9);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.Drag_Drop {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.4rem;
    /* margin-left: 15rem; */
    
}

.Drag_Drop label {
    color: var(--Primary600);
    cursor: pointer;
}

#uploadFile{
    background: white !important;
    border: none !important;
    border-radius: 0;
    font-size: 1.6rem;
    display: none;
}

#nativeUploadFile{
    background: white !important;
    border: none !important;
    border-radius: 0;
    font-size: 1.6rem;
}

.disclaimer{
    font-size: 1.4rem;
    line-height: 3.1rem;
    color: #8E8EA9;
}

.downloadTemplate {
    color: var(--Primary600);
    text-decoration: underline;
    cursor: pointer;
}

