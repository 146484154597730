.tableContainer {
    height: calc(100vh - 205px)
}

.table-manageUsers-controlsContainer {
    display: flex;
    background-color: var(--Neutral170);
    padding: 3rem 2rem 0 2.6rem;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.manage-users-selected {
    color: #32324D;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1;
}
.AdminProject__Assignee {
    position: relative;
}

.AdminProject__Assignee svg{
position: absolute;
bottom: -0.1rem;
right: -0.2rem;
}

.Payroll__ClientView__TopContainer {

    height: calc(100vh - 11.4rem);
    background-color: var(--Neutral100);
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    
}

.Payroll__ClientView__TopContainer__CenterContainer {
  max-height: calc(100vh - 13rem);
  width: 81.2rem;
  min-height: 30rem;
  height: 100%;
  overflow-y: auto;
}

.Payroll__ClientView__TopContainer__CenterContainer__Title {
    color: var(--Neutral800);
    font-size: 1.6rem;
    font-weight: 600;
    margin: 2rem 0rem;
}

.ClientPayrollCard__Container {
  width: 77.1rem;
  height: 40rem;
  padding-top: 2rem;
  border-color: var(--Neutral150);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PayrollCard__Container {
  width: 77.1rem;
  height: 37.2rem;
  padding-top: 2rem;
  border-color: var(--Neutral150);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Payroll__ClientView__Carousel {
    width: 77.3rem;
    height: 39.5rem;
    border: 1px solid var(--Neutral150);
}

.countDown__container {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    font-size: 2.2rem;
    color: var(--Neutral900);
    gap:1rem;
    margin-bottom: 1.5rem;
}

.countDown__container__unit {
display: flex;
flex-direction: column;
align-items: center;
}

.countDown__container__unit__label {
    font-size: 1.2rem;
    color: var(--Neutral500);
}

.countDown__container__unit__value {
    height: 2.9rem;
}

.ClientPayrollCard__Stage {
    width: 15rem;
    display: flex;
    justify-content: center;
    size:1.5rem;
    color: var(--Neutral800);
    margin-bottom: 2rem;
    position: relative;
    align-items: center;
    gap:0.5rem;
}

.ClientPayrollCard__Stage__Name {
  margin-right: 1.5rem;
}

.ClientPayrollCard__Container__Table__Container {
  margin-bottom: 1rem;
}

.ClientPayrollCard__Container__Table__Container > table,.ClientPayrollCard__Container__Table__Container td,.ClientPayrollCard__Container__Table__Container th {
    border-top: 1px solid var(--Neutral150);
    border-bottom: 1px solid var(--Neutral150);
    padding: 1.5rem 6.7rem 1.5rem 4rem;
  }

  .table__cell {
    display: flex;
    gap: 8.3rem;
    align-items: center;
  }

  .left {
    border-right: 1px solid var(--Neutral150);
    padding-left: 7rem;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }

  .table__cell__label {
    font-size: 1.4rem;
    color: var(--Neutral600);
  }

  .table__cell__value {
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    gap:0.5rem;
    
  }

  .ClientPayrollCard__Container__Footer {
    display: flex;
    width: 100%;
    gap: 1rem;
    margin: auto auto;
    padding:0rem 5rem 1rem 5rem;
  }

  .Button__QA {
    margin-right:auto;
  }

  .Payroll__ClientView__History {
    margin-top: 2rem;
  }

  .dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: var(--Neutral300);
    margin-bottom: 2px;
  }

  .dot__blue {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: #66B7F1;
    margin-bottom: 2px;
  }

  button:disabled {
    background-color: var(--Neutral150) !important;
    color: var(--Neutral600) !important;
    opacity: 35% !important;
}

.border {
  border: 2px solid var(--Neutral150) !important;
  padding: 0.3rem;
}

.CircleBorder__Green {
  border: 1px solid var(--Primary600) !important;
  padding: 0.2rem;
}
.ClientPayrollCard__Container__Header {
  display: flex;
  width: 100%;
  margin-bottom: 1.5rem;
  justify-content: space-between;
}

.PayrollCard__Container__Header {
  display: flex;
  width: 100%;
  margin-bottom: 1.5rem;
  justify-content: space-between;
}



.ClientPayrollCard__Container__Header--CenteredTitle {
  display: flex;
  width: 100%;
  margin-bottom:0rem;
  justify-content: space-between;
}

.PayrollCard__Container__Header--CenteredTitle {
  display: flex;
  width: 100%;
  margin-bottom:0rem;
  justify-content: space-between;
}

.ClientPayrollCard__Container__Header__Title--CenteredTitle {
  position:absolute;
  left: 40%;
}


.ClientPayrollCard__Container__Header__FileLink span {
  font-size: 1.4rem;
  white-space: nowrap;
}

.PayrollCard__Container__Header__FileLink span {
  font-size: 1.4rem;
  white-space: nowrap;
}