.TransactionDetail__Container {
    height: 100%;
    width: 100%;
}

.TransactionDetail__Header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

.TransactionDetail__Header #back-btn {
    justify-self:flex-start;
    left:1.5rem;
    position: absolute;
    gap:0.5rem;
    color: var(--Neutral500);
}

.TransactionDetail__GroupContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    font-size: 1.2rem;
}

.TransactionDetail__ParameterContainer {
    display: flex;
    flex-direction: column;
    margin: 0rem 0.5rem;
}

.TransactionDetail__ParameterContainer > .TransactionDetail__ParamterContainer__MultiLine {
    display: flex;
    gap: 0.5rem;
}

.TransactionDetail__ParameterContainer > span {
    width: auto;
    font-size: 1.1rem;
}

/* .TransactionDetail__ParamterContainer div {
    display: flex;
    gap: 0.5rem;
} */

.TransactionDetail__ParameterContainer div {
    border: solid 1px var(--Neutral150);
    color: var(--Neutral600);
    width: auto;
    border-radius: 4px;
    padding: 0.2rem 0.5rem;
    font-size: 1.1rem;
}

.TransactionDetail__Subtotal {
    margin-left: auto;
    width: auto;
    display: flex;
    gap:1rem;
    justify-content: flex-end;
    font-weight: 600;
    margin-top: 1rem;
}