h2,
label {
  color: var(--Neutral800);
  font-weight: 700;
  font-size: 1.4rem;
  margin-bottom: 0;
  line-height: 1.6rem;
  margin-bottom: 0.6rem;
}

.flex_col {
  display: flex;
  flex-direction: column;
}

.flex_row {
  display: flex;
  flex-direction: row;
  gap:1rem;
  align-items: center;
  color: var(--Neutral700, #4A4A6A);
  font-weight: 700;
  font-size: 1.2rem;
}

.flex_row__spaceBetween {
  display: flex;
  justify-content: space-between;
  align-content: center;
  gap:1rem;
}


.onboarding__block--y > input {
  border: 1px solid #dcdce4;
  border-radius: 0.4rem;
  height: 4rem;
  padding-left: 1.6rem;
}

/* .onboarding__block--y .react-datepicker__input-container > input:hover {
  border: none;
} */

.onboarding__block--y > input:hover {
  border: none;
}

h1 {
  font-weight: 700;
}

.companyLogoEdit__Container img {
  max-width: 9.8rem;
  max-height: 9.8rem;
  border-radius: 50%;
}

.cropper-view-box {
  border-radius: 50%;
}

h1 span {
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--Neutral600);
  line-height: 2rem;
  text-align: center;
}

.onboarding__Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    #f6f6f9;
    top:0px;
    height: 100%;
}

header {
  /* -ms-grid-row: 1;
      -ms-grid-column: 1;
      grid-area: bi-report-header; */
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  padding: 0px var(--page-margin-right) 0px 24px;
  border-bottom: 1px solid var(--Neutral150);
  position: relative;
  height: 5.1rem;
  width: 100%;
  min-width: 33rem;
}

header .innerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 55%;
  min-width: 33rem;
  max-width: 79rem;
}

.onboarding__Container__BodyContainer {
  height: 100%;
  width: 55%;
  min-width: 33rem;
  max-width: 79rem;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  margin-top: 15vh;
}

.progressbar__container {
  padding: 4rem 0 2.1rem 0;
  border-bottom: 1px solid var(--Neutral150);
  width: 100%;
}

/* .progressbar__container span[class$="MuiLinearProgress-bar1"] {
  background-color: var(--Primary600);
} */

/* .progressbar__container span[class$="MuiLinearProgress-root"] {
  background-color: var(--Neutral150);
} */

.progressbar__container > span {
  background-color: var(--Neutral150);
}

.progressbar__container > span > span {
  background-color: var(--Primary600);
}




.progressbar__container__barLabelContainer {
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  font: var(--Neutral800);
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.6rem;
}

.progressbar__container__barLabelContainer span {
  font-weight: normal;
}

.onboardingPage__Container {
  margin-top: 3rem;
  width: 100%;
}

.onboardingPage__Container h1 {
  margin-bottom: 1.8rem;
}

.bodyContainer {
  flex-direction: column;
  padding: 2rem;
  border: 0.5px solid var(--Neutral150);
  background-color: white;
  box-shadow: 0px 1px 4px 0px #2121341a;
  border-radius: 0.4rem;
}

.bodyContainer__QBORow {
  /* padding: 0rem 0rem 2rem 0rem; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bodyContainer__QBORow__disabled {
  /* padding-bottom: 2rem; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  opacity: 0.3;
}

.bodyContainer__NOQBORow__check {
  display: flex;
  gap:0.8rem;
  margin: 1rem 0;
}

.bodyContainer__InputSection {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.onboarding__block--y {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap:1rem;
}

.onboarding__block--x {
  display: flex;
  gap: 2rem;
  width: 100%;
}

.footer--btns {

  display: flex;
  gap: 1rem;
  width: 100%;
  padding: 3rem 0rem 2rem 0rem;
  position:relative;
}

.footer--btns button {
  flex: 1 1 auto;
  font-weight: 700 !important;
}
.footer--btns input {
  flex: 5 5 auto;
}

.onboardingPage__Container div[class$="MuiAutocomplete-root"] {
  padding: 0 0 0.4rem 0.4rem;
  background-color: white;
  border: 1px solid var(--Neutral200);
  min-height: 4rem;
  display: flex;
  align-items: center;
}

.Onboarding_page4 div[class$="MuiAutocomplete-root"] {
  min-height: 14.8rem;
  display: flex;
  margin-bottom: auto;
  flex-direction: column;
  height: 100%;
}

.onboardingPage__Container div[class$="MuiTextField-root"] {
  height: 100%;
  align-self: flex-start;
}

.onboardingPage__Container div[class$="MuiAutocomplete-root"] .MuiInput-root {
  height: 100%;
  align-self: flex-start;
}

.Onboarding_page4
  .onboardingPage__Container
  div[class$="MuiAutocomplete-root"]
  .MuiInput-root {
  height: 14.8rem;
  align-self: center;
}

.onboardingPage__Container .MuiInput-root .MuiInput-input {
  padding: 4px 4px 0px 4px !important;
}

.onboardingPage__Container
  button[class$="root-MuiAutocomplete-clearIndicator"] {
  margin-right: 1rem !important;
  padding: 0px !important;
  top: 3px !important;
}

.onboardingPage__Container .MuiChip-label {
  padding-right: 1.2rem !important;
  color: var(--Primary600) !important;
}

.onboardingPage__Container
  div[class$="-MuiAutocomplete-root"]
  .MuiAutocomplete-inputRoot
  .MuiAutocomplete-input {
  width: auto;
  min-width: 30px;
  line-height: 2.5rem;
  margin-left: 1rem;
  height: 2rem;
  margin-top: 0.5rem;
}


.onboarding__block--y .MuiAutocomplete-root:focus-visible {
outline:none;
}

.Onboarding_page4
  .onboardingPage__Container
  div[class$="-MuiAutocomplete-root"]
  .MuiAutocomplete-inputRoot
  .MuiAutocomplete-input {
  min-height: 14.8rem;
  align-self: center;
}

.onboardingPage__Container .MuiChip-root {
  background: var(--Primary100) !important;
  border: 1px solid var(--Primary200);
  height: 2.9rem !important;
  margin: 4px 3px 0 0 !important;
}

.onboardingPage__Container .MuiChip-root .MuiChip-deleteIcon {
  color: var(--Primary600);
  border-left: 1px solid var(--Primary200);
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  margin-right: 0.5rem;
}

.onboardingPage__Container .MuiChip-root .MuiChip-deleteIcon:hover {
  color: var(--Danger200);
}



.QBO__Container__Success {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  gap: 0.5rem;
  color: var(--Primary600);
  font-weight: 700;
  font-size: 1.2rem;
  width: 100%;
  position: relative;
}

.QBO__Container__Failure {
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 0.5rem;
  color: var(--Danger600);
  font-weight: 700;
  font-size: 1.2rem;
  width: 100%;
  position: relative;
}

.QBO__Container__Disconnect {
  color: var(--Danger600);
  font-weight: 400;
  position: absolute;
  right: 0;
  cursor: pointer;
}

.companyLogoEdit__Container {
  display: flex;
  border: 1px dashed #dcdce4;
  border-radius: 4px;
  padding: 1.1rem 1rem;
  align-items: center;
  gap: 1.8rem;
}

/* .companyLogoEdit__Container__ButtonContainer {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
} */

.companyLogoEdit__Container__ButtonContainer h3 {
  color: #85b440;
  font-size: 1.2rem;
  font-weight: 700;
}

.companyLogoEdit__Container__ButtonContainer p {
  font-size: 1rem;
  color: #8e8ea9;
  margin-bottom: 0.8rem;
}

.Input__ParentContainer {
  position: relative;
}

.Input__ParentContainer div[class$="-container"] {
  width: 4rem;
  height: 2rem;
  position: absolute;
  top: 1rem;
  right: 2rem;
}

.Input__ParentContainer div[class$="-control"] {
  width: 4rem;
  height: 2rem;
  min-height: 2rem;
  background: var(--Neutral200);
}

.Input__ParentContainer div[class$="-ValueContainer"] {
  width: 4rem;
}

.Input__ParentContainer div[class$="-singleValue"] {
  top: 0% !important;
  position: absolute;
  word-wrap: normal;
  transform: none;
  color: var(--Neutral900) !important;
  font-weight: 700 !important;
  font-size: 1.2rem !important;
  margin-left: 0.7rem;
}

.Input__ParentContainer div[class$="-IndicatorsContainer"] {
  width: 1rem;
  height: 2rem;
  padding: 0rem;
  display: none;
}

.Input__ParentContainer div[class$="-option"]:hover {
  background-color: rgba(133, 180, 64, 0.1);
  color: #32324d;
}

.Input__ParentContainer div[class$="-option"] {
  background-color: white;
  color: var(--Neutral900);
  padding: 8px 15px;
}

.Input__ParentContainer input {
  width: 100%;
  border: 1px solid #dcdce4;
  border-radius: 0.4rem;
  height: 4rem;
  padding-left: 1rem;
}

.Input__ParentContainer input:hover {
  border: none;
}

div[class$="-MuiPaper-root-MuiDialog-paper"] {
  max-width: 85.9rem;
  width: auto;
  overflow-y: hidden;
}

div[class$="-MuiDialogContent-root"] {
  width: auto;
}

.Summary__ParentContainer {
  padding: 0rem 1.6rem 0rem 1.6rem;
}

.Summary__SectionContainer {
  display: grid;
  border-bottom: 1px solid var(--Neutral150);
  padding: 2rem 0 1rem 0;
  /* display: flex;
  align-items: flex-start; */
  grid-template-rows: auto;
  grid-template-columns: 11.9rem 17.3rem auto;
  grid-template-areas: "title col1 col2";
  column-gap:1rem;
  /* align-items: start; */
  /* grid-auto-flow: row; */
}

.Summary__SectionContainer__Title {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #32324d;
  width: 11.9rem;
  grid-area: title;
}

.Summary__SectionContainer__Body__Col1 {
  grid-area: col1;
}
.Summary__SectionContainer__Body__Col2 {
  grid-area: col2;
}

.Summary__SectionContainer:last-of-type {
  border-bottom: none;
  padding-bottom: 2rem;
}

flex_row__spaceBetween
.Summary__SectionContainer__Body {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #32324d;
  margin-left: -150px;
  column-count: 2;
}

.onboarding__Container__BodyContainer__Completed {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 75%;
  padding-bottom: 15rem;
}

.onboarding__Container__BodyContainer__Completed_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70%;
  gap: 1rem;
  text-align: center;
  color: var(--Neutral600);
  font-size: 1.6rem;
}

.onboarding__Container__BodyContainer__Completed_body h2 {
  color: var(--Neutral800);
  font-size: 1.8rem;
  margin-top: 3rem;
  text-align: center;
}

.Summary__SectionContainer__Body__Col__Block span, .Summary__SectionContainer__Body__Col__Block__ExtendedRow span {
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: var(--Neutral800);
}
.Summary__SectionContainer__Body__Col__Block span::after,.Summary__SectionContainer__Body__Col__Block__ExtendedRow span::after {
  content: ", ";
}
.Summary__SectionContainer__Body__Col__Block span:last-of-type:after, .Summary__SectionContainer__Body__Col__Block__ExtendedRow span:last-of-type:after {
  content: "" !important;
}

.Summary__SectionContainer__Body__Col__Block {
  height: auto;
  margin-bottom: 1rem;
}

.Summary__SectionContainer__Body__Col__Block__ExtendedRow {
  width: 27.5rem;
  margin-bottom: 1rem;
}

.Summary__SectionContainer__Body__Col__Block label {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: var(--Neutral400);
}

.Summary__SectionContainer__Body__Col__Block img {
  width: 3rem;
}

.Summary__SectionContainer__Body__Col1__SuccssText {
  color: var(--Primary600);
  margin-left: 0.5rem;
}
.Summary__SectionContainer__Body__Col1__FailureText {
  color: var(--Danger600);
  margin-left: 0.5rem;
}

.onboarding__block--y h2 span svg {
  width: 15px;
  margin-left: 0.5rem;
}

.inactive_overlay {
  background-color: white;
  opacity: 0.7;
  position:absolute;
  width: 20rem;
  height: 7rem;
}



.bodyContainer__NOQBORow__UploadRow{
  padding: 2rem 2rem;
  margin-bottom: 1rem;
  border: 1px solid #EAEAEF;
  border-radius: 4px;
}

.bodyContainer__NOQBORow__UploadRow__disabled {
  padding: 2rem 2rem;
  margin-bottom: 1rem;
  border: 1px solid #EAEAEF;
  border-radius: 4px;
  opacity: 0.2;
}

@media only screen and (max-width: 747px) {
  .QBO__Container__Disconnect {
    position: relative;
    margin-left: auto;
  }
}

legend {
  display: none;
}

.border_bottom {
  border-bottom: 1px solid var(--Neutral100);
}

.margin-left {
  margin-left: 3rem;
}

.disabled {
  opacity: 0.3; 
}

.flex_row.vertical_padding, .flex_col.vertical_padding {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.flex_row.bottom_border, .flex_col.bottom_border {
  border-bottom: 1px solid var(--Neutral150);
}