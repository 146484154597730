

.tabs-bi{
  display: flex;

  background-color: white;
  justify-content: flex-start;
  padding: 10px var(--page-margin-right) 0px 24px;
  height:auto;
  border-bottom: 1px solid var(--Neutral150);
}

.tabs-bi__link {
  width: auto;
  height: 3.9rem;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: var(--Neutral700);
  display: inline-flex;
  text-align: center;
  padding: 0 3.5px;
  margin-right: 5.5rem;

}

.react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  color: #85B440 !important;
  border-radius: 5px 5px 0 0;
  box-shadow: inset 0px -2px 0px 0px #85B440;
}

.tabs-bi__link:hover{
  cursor: pointer;
  color: #85B440;
}

.filterButtonContainer{
  width: 32px;
  height: 32px;
  border: 0.5px solid rgba(98, 120, 140, 0.4);
  box-sizing: border-box;
  border-radius: 4px;
  justify-self: flex-end !important;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  align-self:center;
  cursor: pointer;
  transform: translateY(-6px);
}

.filterButtonContainer:hover{
  background: #F7F7F7;
  border: 0.5px solid rgba(98, 120, 140, 0.5);
}

.filterButtonContainer:active {
  background: #E8E9EA;
  border: 0.5px solid rgba(98, 120, 140, 0.5);  
}


.filterButtonContainer--Active{
  position:relative;
width: 35px;
height: 35px;
border:0.5px solid var(--Primary600);
box-sizing: border-box;
border-radius: 6px;
justify-self: flex-end !important;
margin-left: auto;
justify-content: center;
align-items: center;
display: flex; 
align-self: center;
cursor: pointer;
}

.filterButtonContainer img {
  width: 40%;
}

.EmployeeAddTable .react-tabs__tab-list  {
  margin-top: 0 !important;
  border-bottom: 1px solid var(--Neutral150);
  margin: 0rem 0 0rem 3.6rem !important;
  display: flex;
  margin-right: 3.6rem !important;
}

.react-tabs__tab-list {
  border-bottom: none;
  margin-bottom:0 !important;
  padding: 0;
}


.react-tabs__tab:focus {
  box-shadow: inset 0px -5px 0px 0px #85B440;
  border-color: hsl(208, 99%, 50%);
  outline: none;
}

.EmployeeAddTable .react-tabs__tab {
  border:none !important;
  color: rgba(142, 142, 169, 1);
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 3.5rem;
}

.EmployeeAddTable .react-tabs__tab-panel--selected {
  margin-top: 2.1rem!important;
  width: 60rem;
}

.EmployeeAddTable .react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  color: #85B440 !important;
  border-radius: 5px 5px 0 0;
  box-shadow: inset 0px -2px 0px 0px #85B440;
}


.filterCount {
position: absolute;
border-radius: 50%;
width: 16px;
height: 16px;
top: 25px;
left: -8px;
padding: 3.5px;
background: var(--Primary600);
border: 0.5px solid var(--Primary600);
display: flex;
align-items: center;

}

.filterCount--bigNumbers {
position: relative;
border-radius: 50%;
width: 16px;
height: 16px;
top: 17px;
left: -17px;
padding: 2.0px;
background: var(--Primary600);
border: 0.5px solid var(--Primary600);
display: flex;
align-items: center;

}
.filterCount > span {
height: 9px;
width: 7px;
letter-spacing: 0.01em;
color: #FFFFFF;
text-align: center;
font-style: normal; 
font-weight: 600;
font-size: 11px;
line-height: 11px;
}

.filterCount--bigNumbers > span {
height: 9px;
width: 7px;
letter-spacing: 0.01em;
color: #FFFFFF;
text-align: center;
font-style: normal; 
font-weight: 600;
font-size: 11px;
line-height: 11px;

}

.filterButtonContainer__icon:hover{
position: absolute;
}