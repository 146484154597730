.BulkAddEmployee__Body__Container {
    padding: 1.8rem 2rem;

}

.BulkAddEmployee__Body__Container > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed #DCDCE4;
    width: 59.1rem;
    height: 30.7rem;
}

.action_btns_container {
display: flex;
justify-content: space-between;
width: 100%;
}

.BulkAddEmployee__Body__Container > div > svg {
margin-bottom: 1.4rem;
}

.Drag_Drop {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.4rem;
}

#uploadFile{
    background: white !important;
    border: none !important;
    border-radius: 0;
    font-size: 1.6rem;
}

.disclaimer{
    font-size: 1.4rem;
    line-height: 3.1rem;
    color: #8E8EA9;
}

.downloadTemplate {
    color: var(--Primary600);
    text-decoration: underline;
    cursor: pointer;
}