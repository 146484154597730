.ReactModal__Overlay{
    z-index: 5;
}

.ReactModal__Content{
    /* height: auto !important; */

    border: 1px solid #E5E5E5 !important;
    box-sizing: border-box;
    box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.05);
}
.ReactModal__Content--after-open{
    /* height: auto !important; */
    border: 1px solid #E5E5E5 !important;
    box-sizing: border-box;
    box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.05);
    }

.title__text--mid--modal {
    font-size: 2rem;
    margin-left: 1.5rem;
    color: var(--Neutral800);
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.01em;
    margin-bottom: 1rem;
  }

.reactFragment{
    margin-right: 2rem;
    margin-top: 0;
    position: relative;
    min-width: 220px;
    max-width: 220px;
}

.label {
    height: 2.1rem;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;  
    line-height: 2.1rem;
    color: #000000;
}

.dateEntryContainer{
    height: unset !important;
    min-width: 320px;
    margin-right: 2rem;
}

.modal__close {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    margin-left: 1rem;
    margin-right: 0rem;
    height: 9px;
    width: 9px;
    flex-wrap: nowrap;
    margin-bottom: 2rem;
    position:relative;
    transform: translateX(-1.8rem) !important;
  }

  .modal__divider{
    left:0;
    border: 0.1px solid #F4F4F4;
    margin-top:2rem;
    margin-bottom: 2rem;
  }

  .modal__row{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap:1rem;
      margin-bottom: 2rem;
      height: 7.5rem;
  }

/* selected filter tag container */
.css-mcdgzz-multiValue {
    background: #E1E4EB !important;
    border-radius: 5px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 2px;
    min-width: 0;
    box-sizing: border-box;
    border-radius: 5rem !important;
    padding-left: 0.7rem;
    padding-right:0;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;

    height: 2.4rem !important;
}

/* selected filter tag text */
.css-135zfgk {
    border-radius: 5px;
    color: var(--Neutral800) !important;
    font-size: 1.4rem;
    overflow: hidden;
    padding: 3px;
    padding-left: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    letter-spacing: 0.01em;
    align-self: center !important;
}

/* selected filter tag X */
.css-tqy58d {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 5px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 4px;
    padding-right: 1.1rem !important;
    box-sizing: border-box;
}


@media only screen and (max-width: 1540px) {

    .row {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
        flex-direction: row;
    }
    /* For tablets: */
}


@media only screen and (max-width: 1250px) {

    .row {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
        flex-direction: column !important;
        
    }

    .d-flex {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
        flex-direction: row !important;
    }

    .ReactModal__Content--after-open{
        /* height: auto !important; */
        border: 1px solid #E5E5E5 !important;
        box-sizing: border-box;
        box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.05);
        }



        .Button--tagStyle {
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            width:11rem;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            height: 24px;
            /* margin-top: 1.72rem; */
            padding-left: 5px;
            transform: translateX(2.9rem);
            
            padding-right: 0px;
            background: var(--Neutral800);
            border-radius: 50px;
        
            -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
          }


}


/* todo */
.css-tqy58d:hover {
    background-color: #E1E4EB !important;
    color: var(--Neutral800) !important;
}

.css-tqy58d {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 10px !important;
}


.Button--tagStyle {
    position: relative;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 7rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    height: 20px;
    align-self: center;
    top:-0.5rem;

    margin-left: -11rem;
    justify-self: flex-start !important;
    background: #8193A3;
    border-radius: 50px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px !important;

    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }


  .Button--tagStyle__text {
    text-align: center;
    justify-content: center;
    margin-left: 0.87rem;
    font-size: 1.2rem;
    letter-spacing: 0.01em;
    color: white;
    line-height: 1px;
    font-style: normal;
    font-weight: normal;
    white-space: nowrap;
  }

  .Button--tagStyle__clear{
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    margin-left: 8rem;
    margin-right: 1.3rem;
    height: 9px;
    width: 9px;
    flex-wrap: nowrap;
    justify-self: flex-end;
  }