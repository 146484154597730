.settings_tab_link {
  width: auto;
  height: 4rem;
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: var(--Neutral700);
  display: inline-flex;
  text-align: center;
  padding: 0 3.5px;
  margin-right: 5.5rem;
  padding-bottom: 1rem;
  align-items: center;
}

.tabs-bi__link--disabled {
  color: var(--Neutral300);
}

.tabs-bi__link--selected {
  background: #fff;
  border-color: #aaa;
  color: #85B440 !important;
  border-radius: 5px 5px 0 0;
  box-shadow: inset 0px -2px 0px 0px #85B440;
}

.tabs-bi__link--disabled:hover {
  cursor: auto;
  color: var(--Neutral300);
  user-select: none;
}
