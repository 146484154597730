
.AddNewLink__Button--inTable {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  color: var(--Neutral500, #8E8EA9);
  cursor: pointer;
}
.LinksTable__Row{
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.AddNewLink__Button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem 1rem;
  border-radius: 6px;
  border: 1px solid var(--Neutral200, #DCDCE4);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  color: #38434C;
  cursor: pointer;
}

.LinksTable__Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding:2rem;
  justify-content: center;
  align-items: center;
}

.LinksTable__Container__NoLinks {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding:2rem;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--Neutral150, #EAEAEF);
}

.LinksTable__Container__NoLinks > div{
  font-weight: 600;
  font-size: 1.6rem;
}

.border_bottom{
  border-bottom: 1px solid var(--Neutral150);
}

.LinksTable__Row--inTable{
    font-size: 1.4rem;
    color: var(--Neutral900);
    /* height: 4rem; */
    border-bottom: 1px solid var(--Neutral150);
    align-items: center;
    display: grid;
    min-height: 4rem;
    grid-template-columns: 36rem 33rem auto auto;
    grid-template-rows: auto;
    grid-template-areas: 
      "file location action-button";
    width: 100%;
  }

  .LinksTable__Row__Left {
    display: flex;
    align-items: center;
    padding-left: 1rem;
    grid-area: file;
    font-size: 1.4rem;
    color: var(--Neutral500, #8E8EA9);
    font-weight: 400;
    height: 100%;
    gap:0.5rem;
    
  }

  .LinksTable__Row__Left > a {
    max-width: 30.4rem;
    width: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .LinksTable__Row__Right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    grid-area: location;
    font-size: 1.4rem;
    color: var(--Neutral500, #8E8EA9);
    font-weight: 400;
    border-left: 1px solid var(--Neutral150);
    height: 100%;
    overflow:hidden !important;
    /* scrollbar-width: thin; */
  }

  .LinksTable__Row__Right:hover {
    overflow: scroll !important;
  }

  .LinksTable__Row__Right:hover > div {
    transform: translateY(0.3rem);
  }

  .LinksTable__Row__Right::-webkit-scrollbar {
    height: 5px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--Neutral150);
    border-radius: 20px;
    border: transparent;
  }
