.first_text {

    font-style: normal;
    font-weight: bold;
    font-size: 33px;
    line-height: 65px;
    /* identical to box height, or 197% */

    align-items: center;
    text-align: center;

    color: var(--Neutral800);
}

.second_text {

    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    color: var(--Neutral700);

}

.loader_container {
    width: 15rem;
}

.CircularProgressbar .CircularProgressbar-text {
    font-size: 32px !important;
}

.report-loader {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: var(--Neutral170);
    /* margin-top: 5.5rem; */
    /* padding-bottom: 300px; */
}

.custom-loader {
    display: 'flex';
    margin: 12;
    padding: 6;
}