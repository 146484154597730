button {
  background-color: transparent;
}

button:disabled {
  background-color: transparent !important;
}

.SubTask__Body__Container__SubTasks__Container__Footer {
  padding-left: 2rem;
}

.SubTask__Body__Container__SubTasks__Container__Footer span {
  white-space: nowrap;
}

.SubTask__Body__Container__SubTasks__Container {
  overflow-y: auto;
}

.SubTaskContainer__NameInput__Container {
  padding: 1.5rem;
}

.SubTask__Body__Container .left {
  padding: 2rem;
}

.SubTask__Container>.SubTask__Body__Container .left {
  padding: 0.7rem 2rem;
}

.SubTask__Body__Container .left .table__cell {
  gap: 5rem;
}

.MilestoneTask__SubTaskList__Container {
  height: 100%;
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--Neutral800);
  max-height: 40rem;
  overflow-y: auto;
}

.InternalQuestions__Container {
  width: 100%;
  max-height: calc(100vh - 51rem);
  height: auto;
  background-color: white;
  margin-top: 2rem;
  border-radius: 4px;
}



.Comments__Container__NoComments {
  width: 100%;
  height: 14rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.MilestoneTaskLine__SubTaskCount__Container {
  display: flex;
  gap: 0.4rem;
  width: 3rem;
  align-items: center;
}

.SubTaskContainer__NotesContainer {
  padding: 1rem 2rem 0rem 2rem;
  max-height: 17rem;
  overflow-y: auto;
}

.SubTask__Body__Container__MilestoneTaskComponent {
  padding: 0rem 2rem;
  width: 100%;
}

.SubTask__Body__Container__MilestoneTaskComponent.full-width {
  padding:0rem;
}

.SubTask__Body__Container__MilestoneTaskComponentButton__Button {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  cursor: pointer;

}

.SubTask__Body__Container__MilestoneTaskComponentButton__Button {
  height: 8.7rem;
  margin: 2rem 0.8rem 2rem 2rem;
  border: 1px solid #EAEAEF;
  border-radius: 4px;
  padding: 2rem;
}

.success:hover svg g {
  fill: var(--Success500);
}

.success:hover svg g path{
  fill: var(--Success500);
}


a {
  color: var(--Neutral600);
}

.MilestoneStagesHeader__Container {
  display: flex;
  width: 100%;
  height: 6rem;
  border-bottom: 1px solid var(--Neutral150);
  align-items: center;
  padding: 0rem 2.5rem;
}

.Payroll__ClientView__TopContainer__CenterContainer textarea:hover,
.Payroll__ClientView__TopContainer__CenterContainer textarea:focus-visible {
  outline: none;
  outline-offset: 0;
}

.Payroll__ClientView__TopContainer__CenterContainer.full-width {
  width: 100% !important;
  margin-left: 4.5rem;
  display: flex;
}

label {
  margin-right: 1rem;
}

.MilestoneStagesHeader__StagesContainer {
  display: flex;
  align-items: center;
  margin-left: auto;

}

.MilestoneStagesHeader__StagesContainer__StageName__Container {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.MilestoneStagesHeader__StagesContainer__Assignee {
  position: relative;
  margin-left: 0.6rem;
}

.MilestoneStagesHeader__StagesContainer__Assignee svg {
  position: absolute;
  bottom: -0.1rem;
  right: -0.2rem;
}

.MilestoneStagesHeader__StagesContainer__StageName__Name {
  font-size: 1.6rem;
  color: var(--Neutral800);
}

.MilestoneStagesHeader__StagesContainer__Assignee .user__circle {
  display: inline-block;
  background-color: #5900B2;
  border-radius: 50%;
  height: 3.6rem;
  width: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MilestoneStagesHeader__StagesContainer__Assignee .user__circle_inner {
  color: white;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  min-width: 3rem;
  font-size: 16px;
}

.MilestoneCard__SubTasks__Container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 0.8rem;
  padding-bottom: 1.5rem;
  overflow-y: auto;
}

.MilestoneCard__SubTasks__List__Container {
  max-height: 20rem;
  overflow-y: auto;
}

.MilestoneCard__SubTasks__List__Container--full-length {
  overflow-y: auto;
}

.FeedbackList__Container__Header{
  border-bottom: 1px solid var(--Neutral150);
  font-size: 1.2rem;
  color: var(--Neutral600, #666687);;
  display: grid;
  grid-template-columns: 56rem 18.3rem;
  grid-template-rows: auto;
  grid-template-areas: 
    "task section";
}

.FeedbackList__Container__Header:nth-child(1) {
  grid-area: task;
}
.FeedbackList__Container__Header div:last-of-type {
  grid-area: section;
  padding-left: 1rem;
}

.MilestoneCard__SubTasks__Title {
  font-size: 1.2rem;
  color: var(--Neutral600);
  border-bottom: 1px solid var(--Neutral150);
  padding-bottom: 0.5rem;
}

.MilestoneCard__SubTasksList__SubTaskLine_SubTaskParams {
  display: flex;
  justify-content: space-between;
  grid-area: task;
  padding-right: 1rem;
  gap:1rem;
  align-items: center;
}

.MilestoneCard__SubTasksList__SubTaskLine_SubTaskParams--no-grid {
  display: flex;
  justify-content: space-between;
  gap:1rem;
  align-items: center;
}

.MilestoneCard__SubTasksList__SubTaskLine__SectionName {
  align-items: center;
  padding-left: 1rem;
  grid-area: section;
  font-size: 1.4rem;
  color: var(--Neutral500, #8E8EA9);
  font-weight: 400;
  border-left: 1px solid var(--Neutral150);
  height: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 17rem;
}

.MilestoneCard__SubTasksList__SubTaskLine_Container{
  font-size: 1.4rem;
  color: var(--Neutral900);
  /* height: 4rem; */
  border-bottom: 1px solid var(--Neutral150);
  align-items: baseline;
  padding-right: 1rem;
  display: grid;
  grid-template-columns: 56rem 18.3rem;
  grid-template-rows: auto;
  grid-template-areas: 
    "task section";
}
.MilestoneCard__SubTasksList__SubTaskLine_Container--no-grid{
  font-size: 1.4rem;
  color: var(--Neutral900);
  height: 4rem;
  border-bottom: 1px solid var(--Neutral150);
  align-items: center;
}




.MilestoneCard__SubTasks__SubTaskContainer>div {
  cursor: pointer;
}

.MilestoneCard__SubTasks__SubTaskContainer__subTaskName {
  display: flex;
  align-items: center;
  width: 100%;
  height: 3.8rem;
}

.MilestoneCard__SubTasks__SubTaskContainer__subTaskName > div {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 38.1rem;
}

.MilestoneCard__SubTasks__SubTaskContainer__subTaskName input {
  width: 100%;
  padding:0.5rem 1rem;
}

.Payroll__Carousel {
  width: 77.3rem;
  height: 41rem;
  border: 1px solid var(--Neutral150);
  border-radius: 4px;
}

.MilestoneCard__Container__Footer {
  display: flex;
  width: 100%;
  margin: auto auto;
  justify-content: flex-end;
}

.MilestoneCard__Container__Footer>.Button__QA {
  margin-right: 1.5rem;
}

.MilestoneCard__Description {
  width: 77.2rem;
  border-top: none;
  background-color: white;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 14rem;
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--Neutral900);
  height: auto;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.MilestoneCard__Description__Container {
  position: relative;
}

.MilestoneCard__Description__Container button {
  position: absolute;
  right: 1.5rem;
  bottom: 1rem;
}


.SubTask__Container {
  background-color: white;
  top: 2rem;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border: 1px solid var(--Neutral150);
  width:100%;
  height: auto;
  position: relative;
  padding-right: 1rem;
}

.SubTask__Container.expandedComments {
  max-width: calc(100vw - 45rem);
}

.SubTask__Body__Container {
  height: 100%;
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--Neutral800);
  /* padding:1rem 0rem; */
}

.SubTask__Header {
  height: 6rem;
  display: flex;
  border-bottom: 1px solid var(--Neutral150);
  padding: 1rem;
  gap: 1rem;
  font-size: 1.6rem;
  align-items: center;
}

.SubTask__Header span {
  margin-right: auto;
}

.SubTask__Header>div>svg {
  cursor: pointer;
}

.MilestoneCard__Container {
  position: relative;
  height: auto;
  border-radius: 4px;
  background-color: white;
  padding-bottom: 1.5rem;
  /* padding-top: 1.6rem; */
  border: solid 1px var(--Neutral150);
  margin-top: 1.5rem;
}

.MilestoneCard__Container > .tabs-bi {
  padding-top:0rem;
}

.MilestoneCard__Container__Header__Email {
  padding: 0rem 1rem;
  margin-right: auto;
  height: 3.2rem;
  display: flex;
  gap:0.5rem;
}

.SubTask__Body__fileLink__Container {
  display: flex;
  gap: 1.6rem;
  align-items: center;
  border: 1px solid var(--Neutral150);
  padding: 2.4rem;
  border-radius: 4px;
}

.SubTask__Body__fileLink__Container img {
  height: 3.5rem;
  width: 4rem;
}

.SubTask__EmailPreview__EmailContacts {
  padding: 0rem 1.6rem;
  border-bottom: 1px solid var(--Neutral150);
  display: flex;
  align-items: center;
}

.SubTask__EmailPreview__EmailContacts:last-child {
  border-bottom: none;
}

.SubTask__EmailPreview__Title {
  padding: 0.8rem 1.6rem;
  border-bottom: 1px solid var(--Neutral150);
  border-top: 1px solid var(--Neutral150);
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  width: 100%;
}

.SubTask__EmailPreview__Title input,.SubTask__EmailPreview__Title div {
  width: 100%;
  line-height: 1.4rem;
  padding:1rem;
}

.SubTask__EmailPreview__Body__Container {
  padding: 2rem 1.6rem;
  overflow-y: auto;
  height: calc(100vh - 39rem);
  font-size: 1.4rem;
}

.SubTask__EmailPreview__Body__Container__AttachmentsContainer {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
  flex-direction: column;
  margin-top: 1rem;
}

.SubTask__EmailPreview__Body__Container__Attachment__Container {
  position: relative;
  width: 100%;
  height: 4.9rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  border: 1px solid var(--Neutral150);
  border-radius: 0.4rem;
  padding: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.SubTask__EmailPreview__Body__Container__Attachment__Container div {
  width: 10rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.SubTask__EmailPreview__Body__Container__Attachment__Container button {
  top: 0.8rem;
}

.SubTask__EmailPreview__EmailContacts__ListContainer {
  overflow-y: auto;
  max-height: 14rem;
}

textarea::placeholder,
input::placeholder {
  color: var(--Neutral300);
  font-weight: normal;
}



.SubTask__EmailPreview__Body__Points__Container {
  border-top: 1px solid var(--Neutral150);
  border-right: 1px solid var(--Neutral150);
  border-left: 1px solid var(--Neutral150);
  border-radius: 4px;
  max-height: calc(100vh - 53rem);
  overflow-y: auto;
  width: 100%;
  padding: 0rem 2rem 1rem 2rem;
  border: none;

}

.full_height {
  height: auto !important;
  max-height: none;
}

.SubTask__EmailPreview__Body__Points__Line {
  position: relative;
  border-bottom: 1px solid var(--Neutral150);
  padding: 0.8rem 6rem 0.8rem 1rem;
  width: 100%;
  font-size: 1.4rem;
  min-height: 3.8rem;
  height: auto;
}

.SubTask__EmailPreview__Body__Points__Line input {
  width: 100%;
}

.SubTask__EmailPreview__Body__QuestionsContainer {
  position: relative;
  border: 1px solid var(--Neutral150);
  border-radius: 0.4rem;
  /* padding: 0.8rem 1rem; */
  width: 100%;
  font-size: 1.4rem;
  /* height: 3.8rem; */
}

.SubTask__EmailPreview__Body__QuestionsContainer div:last-of-type {
  border-bottom: none;
}

.SubTask__EmailPreview__Body__Points__Line__NewPoint {
  border-bottom: 1px solid var(--Neutral150);
  padding: 0.8rem 2rem;
  width: 100%;
  border-radius: 0px;
}

.SubTask__EmailPreview__Body__Points__Line__NewHeader {
  border: 1px solid var(--Neutral150);
  padding: 0.8rem 2rem;
  width: 100%;
  border-radius: 4px;
  margin-top: 1rem;
}

.Question__Container {
  margin-top: 2rem;
  background-color: white;
  max-height: calc(100vh - 44rem);
  border-radius: 4px;
  border: 1px solid var(--Neutral150);
}

.Question__Container .tabs-bi {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.Question__Container .Question__Container__Body {
  border: none;
}

.Question__Container__tabs {
  width: auto;
  height: 3.9rem;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 28px;
  letter-spacing: 0.01em;
  color: var(--Neutral700);
  display: inline-flex;
  text-align: center;
  padding: 0 3.5px;
  margin-right: 5.5rem;
  cursor: pointer;

}



.SubTask__EmailPreview__Body__Points__Line__Container {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0rem 0rem;
  border: 1px solid var(--Neutral150);
  border-radius: 0.4rem;
  margin: 1rem 0rem;
}

.SubTask__EmailPreview__Body__Points__Line__Container li:last-of-type,
.SubTask__EmailPreview__Body__Points__Line__Container div:last-of-type {
  border-bottom: none;
}

.delete-on-hover {
  position: absolute;
  right: 2rem;
  top: 0.3rem;
  /* display: none !important; */
}

.delete-on-hover:hover {
  display: flex;
}

.Comments__Container {
  padding: 1rem 2rem;
  overflow-y: auto;
}

.ChipInputComponent .MuiChip-root span {
  color: var(--Primary600) !important;
}

.ChipInputComponent .MuiInputBase-root {
  padding: 0 !important;
}

.ChipInputComponent .MuiChip-root {
  background-color: var(--Primary200) !important;
}

.MyCustomClass .MuiOutlinedInput-notchedOutline {
  border: none;
  outline: none;
}

.Question__Container__Body {
  height: 100%;
  /* overflow-y: auto; */
  border: 1px solid var(--Neutral150);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  position: relative;
}

.Question__Container__Body .tabs-bi {
  border-bottom: 1px solid var(--Neutral150);
}

.Question__Container__Body .NewComment__Container {
  background-color: white;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.btn--secondary--completed svg path {
  fill: var(--Success500) !important;
}

.SubTaskAcceptReject__Container {
  display: flex;
  gap: 0.5rem;
}

.btn--S--square svg path {
  fill: black;
}

.btn--S--square svg {
  width: 11px;
  height: 11px;
}

.btn--tertiary {
  align-items: center;
  gap: 0.5rem;
}

.SubTask__Body__fileLink__Container input {
  width: 71.5rem;
  height: 3rem;
  border: 1px solid var(--Neutral300);
  margin-top: 1rem;
  padding: 0.8rem;
}

.AddLink__Input__Container {
  padding: 2rem 1.6rem;
}

.AddLink__Input__Container input {
  border: 1px solid var(--Neutral200);
  width: 100%;
  padding: 1rem 1.6rem;
}

.SubTask__Header__OtherActions {
  margin-left: auto;
  display: flex;
  gap: 1rem;
}

.SubTask__EmailPreview__Body__Points__Container__AddButton {
  margin-left: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.HeaderPointsContainer {
  position: relative;
  border: 1px solid var(--Neutral150);
  width: 100%;
  margin: 0.5rem 2rem;
  border-radius: 0.4rem;
}

.SubTask__EmailPreview__Body__PointsHeader {
  border-bottom: 1px solid var(--Neutral150);
  padding: 0.8rem 6rem 0.8rem 1rem;
  width: 100%;
  font-size: 1.4rem;
  min-height: 3.8rem;
  height: auto;
  font-weight: 600;
}

.SubTask__EmailPreview__Body__Points__Line__Container .SubTask__EmailPreview__Body__PointsHeader:first-child {
  border-bottom: 1px solid var(--Neutral150);
}

.SubTask__EmailPreview__Body__Points__Line__Container input:last-of-type {
  border-bottom: none;
}

.with-bottom-borders {
  border-bottom: 1px solid var(--Neutral150) !important;
}

.SubTask__Header__OtherActions svg path {
  fill: #32324D !important;
}

.NoPayrolls__Container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.NoPayrolls__Header {
  font-weight: 700;
  color: var(--Neutral900);
  font-size: 1.8rem;
  line-height: 2.2rem;
  text-align: center;
}

.NoPayrolls__Message {
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: var(--Neutral600);
  text-align: center;
  width: 30.6rem;
}

.MilestoneCard__Container__Header {
  display: flex;
  gap: 1rem;
  height: 6.3rem;
  padding-top: 1rem;
  align-items: center;
}

.MilestoneCard__Container__Header__FileLink {
  display: flex;
  gap: 2rem;
  height: 3.4rem;
  margin-bottom: 0rem;
  padding-right: 1rem;
  font-size: 1.4rem;
}


.MilestoneCard__Container__Header__FileLink span {
  white-space: nowrap;
}

.table__cell__value .calendar-custom-date-display {
  margin-left: 0.5rem;
}

.SubTaskLink__Container {
  height: 5rem;
  padding: 0rem 2rem;
  display: flex;
  align-items: center;
}

.SubTaskLink__Container button {
  margin-left: 1rem;
}

.SubTaskLink__Text {
  width: 53.1rem;
  margin-left: 8rem;
  overflow: hidden;
  max-height: 4.5rem;
}



.SubTaskLinksList__Container {
  height: auto;
  max-height: 15rem;
}

.SubTaskLinksList__Container .SubTaskLink__Container {
  border-bottom: 1px solid var(--Neutral150);
}

.SubTaskLinksList__Container .SubTaskLink__Container:last-child {
  border-bottom: none;
}

.SubTask__Body__Container__MilestoneTaskComponent__NoComponents {
  padding: 2rem;
}

.MilestoneCard__SubTasks__SubTaskContainer__DatePicker--inline {
  width: 10rem;
}

.calendar-custom-date-display {
  white-space: nowrap;
}

.calendar-custom-input {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.MilestoneStagesHeader__StageButtons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.MilestoneTaskComponent__ExportQBO__Footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap:1rem;
  color: var(--Neutral600);
  font-size: 1.2rem;
  padding-bottom: 1rem;
}


.MilestoneTaskComponent__ExportQBO__Footer button {
  
  margin-right: 1rem;
}

.SubTask__Container__Loading {
  height: 33.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  flex-direction: column;
  gap:2rem;
}


.MonthEndComments__Container {
  width: 46rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 0rem 4rem 0rem 1rem;
  display: flex;
  flex-direction: column;
}

.MonthEndComments__Container--collapse {
  width: 9rem;
}

.TicketingSystemComments__Container--collapse {
  width: 9rem;
}

.MonthEndComments__Container__List {
  margin-top:1rem;
  display: flex;
  gap:1rem;
  flex-direction: column;
  overflow-y: auto;
}

.MonthEndComments__Container__Title {
  display: flex;
  align-items: center;
  gap:0.5rem;
  margin-bottom: -1rem;
  cursor: pointer;
}

.MonthEndComments__Container__Title .cell-comments__tabs {
  display: flex;
  background-color: transparent;
  justify-content: flex-start;
  padding: 10px var(--page-margin-right) 0px 24px;
  height: auto;
  border-bottom: 1px solid var(--Neutral150);
} 

.MonthEndComments__Container__Title__DiscussionIconContainer {
  position: relative;
}

.MonthEndComments__Container__Title div svg {
  cursor: pointer;
}

.MonthEndComments__Container__Title__DiscussionIconContainer__UnresolvedCount {
  position:absolute;
  bottom: 0rem;
  right: -0.7rem;
  height: 1.3rem;
  width: 1.3rem;
  background-color: var(--Primary600);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
}

.cell_comments_tab_link {
  width: auto;
  height: 4rem;
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: var(--Neutral700);
  display: inline-flex;
  text-align: center;
  padding: 0 3.5px;
  margin-right: 5.5rem;
  padding-bottom: 1rem;
  align-items: center;
  cursor: pointer;
}

.cell-comments__tabs .react-tabs__tab--selected {
  background: transparent;
}

.cell-comments__tabs ul {
  display: flex;
}

.MonthEndComments__Container__Title span {
font-size: 1.4rem;
color: var(--Netural900);
}

.MonthEndComments__CommentsContainer {
  display: flex;
  flex-direction: column;
  gap:2rem;
  overflow-y: auto;
  cursor: pointer;
}

.MonthEndComments__Comment__Container {
background-color: white;
border: 1px solid var(--Neutral150);
border-radius: 0.4rem;
display: flex;
flex-direction: column;
font-size: 1.2rem;
}

.MonthEndComments__Comment {
  padding:1rem 1.3rem 1rem 1.6rem;
}

.MonthEndComments__Comment__Container.SelectedCommentContainer {
  /* border:1px solid ; */
  box-shadow: inset 0px 0px 4px 1px var(--Primary600);
}


.MonthEndComments__Comment__Title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 0rem;
}
.MonthEndComments__Comment__Title__Image {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  background-color: blue;
}

.MonthEndComments__Comment__Title__AuthorName {
  font-weight: 700;
  font-size: 1.2rem;
  color: var(--Neutral900);
  margin-right: auto;
}

.MonthEndComments__Comment__Title__Date {
  color: var(--Neutral300);
  font-weight: 700;
  font-size: 1.2rem;
  margin-left: 0.5rem;
}

.MonthEndComments__Comment__Body {
  color: var(--Neutral900);

}

.MonthEndComments__Comment__Buttons {
  display: flex;
  margin-left: 1rem;
  align-items: center;
}

.MonthEndComments__Comment__Reply textarea:focus, .MonthEndComments__Comment__Body textarea:focus{
height: 10rem !important;
}

.NewComment__Container .ql-editor {
  padding-right: 9rem;
}

.FeedbackList__Container {
  
  padding:2rem;
}

.FeedbackList__List {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 29rem);
}

.MilestoneCard__Container__Feedback__Filter {
  position: absolute;
  top:1rem;
  right: 1.5rem;
  width: 15rem;
}