.MuiDataGrid-cell:focus{
outline: none !important;
}

.action_btns_container--right {
display: flex;
gap: 1rem;
}

/* .btn--M:disabled {
    padding: 0rem 1rem !important;
} */

/* .btn--primary:disabled {
    padding: 0rem 0rem !important;
} */

.MuiDataGrid-row:hover {
    cursor: pointer;
}

.SharepointExplorer__FileUpLoadNaming__Container {
    height: 6.1rem;
    width: 100%;
    background-color: var(--Neutral100);
    border-top:1px solid var(--Neutral150);
    padding: 1rem 1.6rem;
    display: flex;
    gap:1.5rem;
    flex-direction: row;
    align-items: center;
    font-size: 1.4rem;
    color: var(--Neutral800);
}

.SharepointExplorer__FileUpLoadNaming__Container input {
    width: 88%;
    height: 100%;
    padding: 0rem 1.6rem;
}

.SharepointLoadingOverlay__Container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 2rem;
    flex-direction: column;
    gap:2rem;
  }

  .SharepointLoadingOverlay__Container span {
    font-size: 2rem;
  }

  .FolderPath {
    display: flex;
    gap:1rem;
    align-items: center;
    margin-right: 1rem;
    white-space:nowrap;
    
  }

  .FolderPathContainer {
    display: flex;
    gap:0.5rem;
  }