.MuiOutlinedInput-notchedOutline {
    border: none !important
}

#assignee-input-label{
    color:#212134 ;
}

label {
    color:#212134 ;
}

.MuiFormLabel-root {
    color:#212134 !important;
    padding-top: 1rem;
}

.MuiList-root {
    padding-top: 2rem !important;
}

.MuiAutocomplete-option {
    display: flex;
    gap:1rem;
}