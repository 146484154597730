.Registration__Container{
    display:flex;
    flex-direction: column;
    justify-content: space-between; 
}

.completeInfo{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: var(--Primary600);
    margin-bottom: 1.3rem;
}

.Registration__Container_Entry_Row{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2.6rem;
}

.Registration__Container_Entry_Row:nth-of-type(1){
    margin-top:1.8rem;
}


.Registration__Container h1{
    height: 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 40px;
    letter-spacing: 0.01em;
    color: var(--Neutral800);
    margin-top:-2rem;
    margin-left: -0.5rem;
}

.Registration__Container h3{
    font-size: 2rem;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 0.01em;
    color: var(--Neutral800);
}

.Registration__Container img {
    margin-bottom: 3rem;
    align-self: center;
}



.Registration__Container__EntryContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1.5rem;
    width: 100%;
}

.Registration__Container__EntryContainer label{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}
    
.Registration__Container__EntryContainer input{
    padding: 1.5rem 1.8rem;
    border: 0.5px solid rgba(98, 120, 140, 0.4);
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: var(--Neutral800);
}

.TermsNServices__Container span{
    font-style: normal;
    font-weight: normal;
    font-size: 12px !important;
    line-height: 18px !important;
    color: #000000 !important;
}