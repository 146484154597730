.successContainer {
    display:flex;
    flex:1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10rem;
}

.successContainer img{
    height: 26rem;
    margin-bottom: 2.7rem;
}

.successContainer h1{
    font-style: normal;
    font-weight: bold;
    font-size: 3.3rem;
    line-height: 6.5rem;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--Neutral800);
}

.successContainer h1 span{
    color: var(--Primary600);
}

.successContainer h4{
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 2.5rem;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--Neutral700);
}

.successContainer h4 a{
    color: var(--Primary600);
    text-decoration: underline;
}

.successContainer button{
    color: white;
    border-radius: 0.8rem;
    background-color: var(--Neutral800);
    width: 21rem;
    height: 4rem;
    font-weight: 600;
    font-size: 12;
    box-shadow: none;
    margin-top: 7rem;
}