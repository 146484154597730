.searchBox {
  display: flex;

}

.searchBox input {
  background: #FFFFFF;
  border: 1px solid #E1E4EB;
  box-sizing: border-box;
  border-radius: 0.4rem;
  width: 274px;
  height: 3.2rem;
  padding-left: 1.9rem;
  padding: 0 36px;
  display: flex;
  color: var(--Neutral800);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 5px;
}

.searchBox input:hover {
  border: none;
}

.searchBox label {
  position: relative;
}

.searchBox input::placeholder {
  position: relative;
  left: 0rem;
  top: 0rem;
  bottom: 10px;
  width: 274px;
  color: #BCBCBC;
  line-height: 5px;
  height: 3.2rem;
}

.searchBox svg {
  position: relative;
  top: 1.5rem;
  left: 2.8rem;
}

.searchIcon {
  top: 1.1rem !important;
}

.searchBox svg:nth-of-type(2) {
  left: 0rem;
  margin: -0.5rem;
  margin-bottom: 2.4rem;
}

.settingsSearchBox {
  display: flex;
  margin-left: auto;
  align-items: center;
  margin-right: 1rem;
}

.settingsSearchBox input {
  line-height: 3;
  height: 3.3rem;
}

.settingsSearchBox svg {
  top: 0 !important;
}

.settingsSearchBox svg:nth-of-type(2) {
  margin: 0rem;
}