a {
  color: var(--Neutral600);
}

.MilestoneStagesHeader__Container {
  display: flex;
  width: 100%;
  height: 6rem;
  border-bottom: 1px solid var(--Neutral150);
  align-items: center;
  padding: 0rem 2.5rem;
}

.Payroll__ClientView__TopContainer__CenterContainer textarea:hover,
.Payroll__ClientView__TopContainer__CenterContainer textarea:focus-visible {
  outline: none;
  outline-offset: 0;
}

label {
  margin-right: 1rem;
}

.MilestoneStagesHeader__StagesContainer {
  display: flex;
  align-items: center;
  margin-left: auto;

}

.MilestoneStagesHeader__StagesContainer__StageName__Container {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.MilestoneStagesHeader__StagesContainer__Assignee {
  position: relative;
  margin-left: 0.6rem;
}

.MilestoneStagesHeader__StagesContainer__Assignee svg {
  position: absolute;
  bottom: -0.1rem;
  right: -0.2rem;
}

.MilestoneStagesHeader__StagesContainer__StageName__Name {
  font-size: 1.6rem;
  color: var(--Neutral800);
}

.MilestoneStagesHeader__StagesContainer__Assignee .user__circle {
  display: inline-block;
  background-color: #5900B2;
  border-radius: 50%;
  height: 3.6rem;
  width: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MilestoneStagesHeader__StagesContainer__Assignee .user__circle_inner {
  color: white;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  min-width: 3rem;
  font-size: 16px;
}

.MilestoneCard__SubTasks__List__Container {
  max-height: 9.7rem;
  overflow-y: auto;
}

.MilestoneCard__SubTasks__Title {
  font-size: 1.2rem;
  color: var(--Neutral600);
  border-bottom: 1px solid var(--Neutral150);
  padding-bottom: 0.5rem;
}

.MilestoneCard__SubTasks__SubTaskContainer {
  font-size: 1.4rem;
  color: var(--Neutral900);
  height: 4rem;
  border-bottom: 1px solid var(--Neutral150);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap:1rem;
}

.PayrollCard__SubTasks__SubTaskContainer>div {
  cursor: pointer;
}

.PayrollCard__SubTasks__SubTaskContainer__subTaskName {
  display: flex;
  align-items: center;
}

.Payroll__Carousel {
  width: 77.3rem;
  height: 41rem;
  border: 1px solid var(--Neutral150);
  border-radius: 4px;
}

.MilestoneCard__Container__Footer {
  display: flex;
  width: 100%;
  margin: auto auto;
  justify-content: flex-end;
}

.PayrollCard__Container__Footer>.Button__QA {
  margin-right: 1.5rem;
}

.PayrollCard__Description {
  width: 77.2rem;
  border-top: none;
  background-color: white;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 14rem;
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--Neutral900);
  height: auto;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.PayrollCard__Description__Container {
  position: relative;
}

.PayrollCard__Description__Container button {
  position: absolute;
  right: 1.5rem;
  bottom: 1rem;
}


.SubTask__Header {
  height: 6rem;
  display: flex;
  border-bottom: 1px solid var(--Neutral150);
  padding: 2rem;
  gap: 1rem;
  font-size: 1.6rem;
  align-items: center;
}

.SubTask__Header span {
  margin-right: auto;
}

.SubTask__Header>div>svg {
  cursor: pointer;
}

.PayrollCard__Container {
  margin-top: 2rem;
  position: relative;
  height: auto;
  border-radius: 4px;
  background-color: white;
  padding-bottom: 1.5rem;
  padding-top: 2rem;
}

.SubTask__Body__fileLink__Container {
  display: flex;
  gap: 1.6rem;
  align-items: center;
  border: 1px solid var(--Neutral150);
  padding: 2.4rem;
  border-radius: 4px;
}

.SubTask__Body__fileLink__Container img {
  height: 3.5rem;
  width: 4rem;
}

.SubTask__EmailPreview__EmailContacts {
  padding: 0rem 1.6rem;
  border-bottom: 1px solid var(--Neutral150);
  display: flex;
  align-items: center;
}

.SubTask__EmailPreview__EmailContacts:last-child {
  border-bottom: none;
}



.SubTask__EmailPreview__Body__Container__AttachmentsContainer {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
  flex-direction: column;
  margin-top: 1rem;
}

.SubTask__EmailPreview__Body__Container__Attachment__Container {
  position: relative;
  width: 100%;
  height: 4.9rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  border: 1px solid var(--Neutral150);
  border-radius: 0.4rem;
  padding: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.SubTask__EmailPreview__Body__Container__Attachment__Container div {
  width: 10rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.SubTask__EmailPreview__Body__Container__Attachment__Container button {
  top: 0.8rem;
}

.SubTask__EmailPreview__EmailContacts__ListContainer {
  overflow-y: auto;
  max-height: 14rem;
}

textarea::placeholder,
input::placeholder {
  color: var(--Neutral300);
  font-weight: normal;
}


.full_height {
  height: auto !important;
  max-height: none;
}

.SubTask__EmailPreview__Body__Points__Line {
  position:relative;
  border-bottom: 1px solid var(--Neutral150);
  padding: 0.8rem 5rem 0.8rem 1rem;
  width: 100%;
  font-size: 1.4rem;
  height: auto;
}

.SubTask__EmailPreview__Body__QuestionsContainer {
  position:relative;
  border: 1px solid var(--Neutral150);
  border-radius: 0.4rem;
  /* padding: 0.8rem 1rem; */
  width: 100%;
  font-size: 1.4rem;
  /* height: 3.8rem; */
}

.SubTask__EmailPreview__Body__QuestionsContainer div:last-of-type {
  border-bottom: none;
}

.SubTask__EmailPreview__Body__Points__Line__NewPoint {
  border-bottom: 1px solid var(--Neutral150);
  padding: 0.8rem 2rem;
  width: 100%;
  border-radius: 0px;
}

.SubTask__EmailPreview__Body__Points__Line__NewHeader {
  border: 1px solid var(--Neutral150);
  padding: 0.8rem 2rem;
  width: 100%;
  border-radius: 4px;
  margin-top: 1rem;
}

.Question__Container .tabs-bi {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}


.Question__Container__tabs {
  width: auto;
  height: 3.9rem;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 28px;
  letter-spacing: 0.01em;
  color: var(--Neutral700);
  display: inline-flex;
  text-align: center;
  padding: 0 3.5px;
  margin-right: 5.5rem;
  cursor: pointer;

}



.SubTask__EmailPreview__Body__Points__Line__Container {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0rem 0rem;
  border: 1px solid var(--Neutral150);
  border-radius: 0.4rem;
  margin: 1rem 0rem;
}

.SubTask__EmailPreview__Body__Points__Line__Container li:last-of-type,
.SubTask__EmailPreview__Body__Points__Line__Container div:last-of-type {
  border-bottom: none;
}

.delete-on-hover {
  position: absolute;
  right: 1rem;
  top: 0.3rem;
  /* display: none !important; */
}

.delete-on-hover:hover {
  display: flex;
}

.ChipInputComponent .MuiChip-root span {
  color: var(--Primary600) !important;
}

.ChipInputComponent .MuiInputBase-root {
  padding: 0 !important;
}

.ChipInputComponent .MuiChip-root {
  background-color: var(--Primary200) !important;
}

.MyCustomClass .MuiOutlinedInput-notchedOutline {
  border: none;
  outline: none;
}

.Question__Container__Body .NewComment__Container {
  background-color: white;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.btn--secondary--completed svg path {
  fill: var(--Success500) !important;
}

.SubTaskAcceptReject__Container {
  display: flex;
  gap: 0.5rem;
}

.btn--S--square svg path {
  fill: black;
}

.btn--S--square svg {
  width: 11px;
  height: 11px;
}

.btn--tertiary {
  align-items: center;
  gap: 0.5rem;
}

.SubTask__Body__fileLink__Container input {
  width: 71.5rem;
  height: 3rem;
  border: 1px solid var(--Neutral300);
  margin-top: 1rem;
  padding: 0.8rem;
}

.AddLink__Input__Container {
  padding: 2rem 1.6rem;
}

.AddLink__Input__Container input {
  border: 1px solid var(--Neutral200);
  width: 100%;
  padding: 1rem 1.6rem;
}

.PayrollLink__Container {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.PayrollLink__Container div{
white-space: nowrap;
}

.SubTask__Header__OtherActions {
  margin-left: auto;
  display: flex;
  gap: 1rem;
}

.SubTask__EmailPreview__Body__Points__Container__AddButton {
  margin-left: auto;
  margin-top: 1rem;
}

.HeaderPointsContainer {
  position: relative;
  border: 1px solid var(--Neutral150);
  width: 100%;
  margin: 0.5rem 2rem;
  border-radius: 0.4rem;
}

.SubTask__EmailPreview__Body__PointsHeader {
  border-bottom: 1px solid var(--Neutral150);
  padding: 0.8rem 5rem 0.8rem 1rem;
  width: 100%;
  font-size: 1.4rem;
  height: auto;
  font-weight: 600;
}

.SubTask__EmailPreview__Body__Points__Line__Container .SubTask__EmailPreview__Body__PointsHeader:first-child{
border-bottom: 1px solid var(--Neutral150);
}

.SubTask__EmailPreview__Body__Points__Line__Container input:last-of-type {
  border-bottom: none;
}

.with-bottom-borders {
  border-bottom: 1px solid var(--Neutral150) !important;
}

.SubTask__Header__OtherActions svg path{
fill: #32324D!important;
}

.NoPayrolls__Container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.NoPayrolls__Header {
  font-weight: 700;
  color: var(--Neutral900);
  font-size: 1.8rem;
  line-height: 2.2rem;
  text-align: center;
}

.NoPayrolls__Message {
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: var(--Neutral600);
  text-align: center;
  width: 30.6rem;
}

.PayrollCard__SubTasks__Container{
  width: 100%;
}