.Login__Container{
    display:flex;
    flex-direction: column;
    justify-content: space-between; 
}

.Login__Container__Divider {
    margin-top: 2rem;
    margin-bottom: 2rem;
    height: 1px;
    width: 100%;
    border: 1px solid var(--Neutral100);
}

.Login__Container h1{
    height: 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 40px;
    letter-spacing: 0.01em;
    color: var(--Neutral800);
    margin-top:-2rem;
    margin-left: -0.5rem;
}

.errorFont {
    color:red !important;
}

.Login__Container__EntryContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
    width: 100%;
}

.Login__Container__EntryContainer label{
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}

.Login__Container__EntryContainer input{
    padding: 1.4rem 1.8rem;
    border: 0.5px solid rgba(98, 120, 140, 0.4);
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.Auth__BottomSection{
    margin-left: 0rem;
    width:100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formErrors {
    color: red;
    font-size: 11px;
    font-weight: 500;
    margin-top: -6px;
    margin-bottom: 6px;
}

.Auth__GoogleBtn{
    display: flex;
    width: 100%;
    
}
.Auth__GoogleBtn button{
    color: var(--Neutral800);
    font-size: 1.2rem;
    display: flex;
    height: 5.2rem;
    width: 100%;
    background-color: white;
    border: 1px solid var(--Neutral200);
    box-sizing: border-box;
    /* box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05) !important; */
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    gap:1rem;
    font-weight: 500;
}