.bold {
    font-weight: 700;
}

.dateSelection {
    width: 25rem;
    display: flex;
    gap:2rem;

}

.FinancialReport__Container {
    /* width: 84%; */
    display: flex;
    flex-direction: column;
    margin:0 auto 0 auto;
    position: relative;
    padding: 2.3rem;
    background-color: white;
}

.FinancialReport__Container_ReportHeader{
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    position:relative;

}

.FinancialReport__Container_ReportHeader h2{
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 1.6rem;
    color: var(--Neutral800);
    margin-bottom: 0.8rem;
}

.FinancialReport__Container_ReportHeader h3 {
font-weight: 700;

}

.FinancialReport__Container_ReportHeader div {
    color: var(--Neutral500);
    font-size: 1.6rem;
}

.FinancialReport__Container_ReportHeader__Controls {
    width:100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom:1rem;
    gap: 1rem;
    height: 3.6rem;
}

.FinancialReport__Container_ReportHeader__Controls__Zoom {
    margin-left: auto;
    display: flex;
    gap:1rem;
    align-items: center;
}

.FinancialReport__Container_ReportHeader__Controls__SyncVersion {
    display: flex;
    gap:1rem;
    align-items: center;
}


.FinancialReport__Container_ReportHeader__Controls__Zoom > div, .FinancialReport__Container_ReportHeader__Controls__SyncVersion > div{
    font-size: 1.2rem;
    height: 3rem;
}

.MuiDataGrid-cell:focus {
box-shadow: inset 0px 0px 2px 1px var(--Primary600) !important;
}

.NewCommentInput {
    margin-left: 2rem;
    margin-bottom: 2rem;
    padding:1rem;
}

.MonthEndComments__Comment__Reply textarea {
    border: 1px solid var(--Neutral150);
    padding:1rem;
}

.MonthEndComments__Comment__RepliesContainer {
    padding-left: 1.5rem;
    margin: 1rem 0rem;
    gap:1rem;
    display: flex;
    flex-direction: column;
}

.MonthEndComments__Comment__Reply {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
    gap:1rem;
    
}

.FinancialReport__Container_ReportHeader__Title {
    width: 100%;
    text-align: center;
    
}

.FinancialReport__Container_ReportHeader__Title button {
    gap:1rem;
    color: var(--Neutral500);
}

.MonthEndComments__Container__Title .tabs-bi {
background-color: transparent !important;
}

.success.resolved > svg > path {
    fill: var(--Success500) !important;
    cursor: pointer;
}

.AssigneeEdit__Container {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    gap:1rem;

}

.AssigneeEdit__Container span {
    color: var(--Neutral600);
    font-size: 1.2rem;
}

.CommentEditFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.1rem 1.6rem;
    border-top: solid 1px var(--Neutral150);
}

.MonthEndComments__Comment__AssignedTo {
    color: var(--Neutral600);
    font-size: 1.2rem;
}

.MonthEndComments__Comment__NormalViewHeader {
    display: flex;
    justify-content: space-between;
}

.quill{
    width: 100%;
}

.QBONotConnected__Container {
    width: 100%;
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20rem;
    gap:2rem;
    font-size: 2rem;
    color: var(--Danger500);
}