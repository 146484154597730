.profile-icon, .company-icon, .company-icon-header {
    height: 29px;
    width:29px;
    border-radius: 50%;
    display: flex;
    align-items: center;
}
.company-icon {
    height: 29px;
}
.company-icon-header {
    height: 29px;
    width:29px;
}

.MuiMenuItem-root {
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 1.4rem !important;
  line-height: 24px !important;
  /* identical to box height, or 171% */
  height: max-content !important;
  letter-spacing: 0.01em !important;
  color: var(--Neutral700) !important;
}

.MuiAvatar-root {
  width: 32px !important;
  height: 32px !important;
}

.companyMenuPaper {
  width: fit-content;
}

