.CompanyPicker__Container {
    display: flex;
    justify-content: flex-start;
    width: 20rem;
    margin-bottom: auto;
    margin-bottom: 1.5rem;
    margin-top: 2rem;

}

/* .ExportQBOToExcel__ExportContainer {
    border: 1px solid var(--Neutral150, #EAEAEF);
} */

.ExportQBOToExcel__Container__Parent > div {
    border: 1px solid var(--Neutral150, #EAEAEF);
}

.ExportQBOToExcel__Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem;
    background-color: white;
    width: 76.7rem;
    height: 44.8rem;
    
}

.ExportQBOToExcel__Container__Parent {
    /* padding: 2rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), #F6F6F9;
    height: 100%;
    gap:2.1rem;
    padding-top: 2.1rem;
}

.ExportQBOToExcel__Footer {
    padding: 1rem 1rem;
    width: 100%;
    height: 100%;
    border-radius: 0.4rem;
}

.ExportQBOToExcel__Container__InputContainer__List {
    overflow-y: auto;
    max-height: 34.2rem;
}

.ExportQBOToExcel__Container__InputContainer {
    display: grid;
    grid-template-columns: 1% 28% 28% 28%;
    grid-template-rows: auto;
    column-gap: 5%;
    margin-bottom: 1rem;
    padding: 0rem 1rem 0rem 1rem;
    border-bottom: var(--Neutral150) 1px solid;
}

.ExportQBOToExcel__Container__Header {
    display: grid;
    grid-template-columns: 1% 30% 30% 30%;
    grid-template-rows: auto;
    column-gap: 5%;
    margin-bottom: 1rem;
    padding: 0rem 1rem;
    font-size: 1.8rem;
    border-bottom: 1px solid grey;
    height: 4rem;
}


.ExportQBOToExcel__Container__InputContainer__Label {
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: var(--Neutral800);
    margin-bottom: 0.4rem;
}

.ExportQBOToExcel__Container__InputContainer__DateContainer {
    padding: 1rem;
    border: 1px solid var(--Neutral200);
    border-radius: 0.4rem;
    margin-bottom: 1rem;
}

.ExportQBOToExcel__Container__InputContainer__DateContainer.disabled{
    padding: 1rem;
    border: 1px solid var(--Neutral200);
    border-radius: 0.4rem;
    margin-bottom: 1rem;
    background: var(--Neutral150, #EAEAEF);
    opacity: 0.5;
    color: var(--Neutral600, #666687);
    font-family: SF Pro Text;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-feature-settings: 'clig' off, 'liga' off;
}

.ExportQBOToExcel__Container__InputContainer__DateContainer .react-datepicker__input-container .calendar-custom-input {
    height: 1.9rem;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.ExportQBOToExcel__Container__InputContainer__DateContainer .react-datepicker__input-container .calendar-custom-input svg {
    width: 1.6rem;
    height: 1.6rem;
}

.ExportQBOToExcel__Container__InputContainer__DateContainer .react-datepicker__input-container .calendar-custom-input svg path {
    fill: var(--Neutral500);
}

.ExportQBOToExcel__Container__InputContainer__DateContainer .react-datepicker__input-container .calendar-custom-input span {
    font-size: 1.4rem;

}

.ExportQBOToExcel__Container__InputContainer__Title {
    font-size: 1.5rem;
    margin-bottom: 0rem;
    padding: 1.2rem 0;
}

.ExportQBOToExcel__Container__Grid {
    display: flex;
    flex-direction: column;
    width: 100%;
}


.SharepointExplorer__Container {
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    width: 80rem;
}

.ExportQBOToExcel__Container__Header__Label {
padding: 0.6rem 0rem;
color: var(--Neutral800, #32324D);
font-feature-settings: 'clig' off, 'liga' off;

/* Pi (Bold) - Small button text */
font-family: SF Pro Text;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 16px; /* 133.333% */
}

.ExportQBOToExcel__Container__Options {
    border-radius: 4px;
    border: 1px solid var(--Neutral150, #EAEAEF);
    background: var(--Neutral0, #FFF);
    display: flex;
    flex-direction: column;
    width: 76.7rem;
}

.ExportQBOToExcel__Container__Options > div {
    height: 6rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 2rem;
}
.ExportQBOToExcel__Container__Options > div:last-child {
    height: 6rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}







