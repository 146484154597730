.settings-header-bi {
  /* -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: bi-report-header; */
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: flex-end;
  padding: 0px var(--page-margin-right) 0px 24px;
  border-bottom: none;
  position: relative;
  height: 51px;
  width: 100%;
  gap: 1rem;

}

.header-controllers__container {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.Title__refreshStatus {
  display: flex;
}

.Title__refreshStatus svg {
  margin-right: 0.36rem;
}

.Title__refreshStatus label {
  margin-bottom: 0;
  margin-left: 0.8rem;
  display: flex;
  align-items: center;
  padding: 0 0.7rem;
  white-space: nowrap;
}

.Updated {
  font-size: 14px !important;
  line-height: 14px !important;
  letter-spacing: 0.01em;
  color: #C4C4C4 !important;
  padding-bottom: 0 !important;
}

.updating {
  height: 19px;
  width: auto;
  background: var(--Primary600);
  border-radius: 6px;
  color: white;
  opacity: 0.25;
  margin-left: 1.5rem !important;
}

.Title__refreshStatus button {
  margin-left: 1.5rem;
  height: 2rem;
  width: auto;
  background: var(--Primary600);
  border-radius: 6px;
  color: white;
  padding: 0.45rem 0.7rem;
  display: flex;
  align-items: center;
  box-shadow: none;
  border: none;
}

header>p {
  color: #FFFFFF;
  font: bold 1.6em;
  margin-left: 31px;
  padding-top: 20px;
}

.Title__Container .title,
.title {
  -ms-flex-item-align: start;
  align-self: center;
  display: flex;
  align-items: center;
}

.title {
  -ms-flex-item-align: start;
  align-self: center;
  display: flex;
  align-items: center;
  margin-right: auto;
}




.title__Icon {
  display: inline-block;
}

.title__text--mid {
  font-size: 1.8rem;
  line-height: 2.2rem;
  margin-left: 2rem;
  color: var(--Neutral800);
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.01em;
  white-space: nowrap;
}


.filterIcon {
  transform: translateX(-20px)
}

.filterIcon__text {
  transform: translateX(-20px)
}

.btn--updateProjections {
  background: #FFFFFF;
  border: 0.5px solid rgba(98, 120, 140, 0.4);
  box-sizing: border-box;
  border-radius: 8px;
  margin-left: 1rem;
  padding: 17px 16px;
  color: var(--Neutral800);
  font-weight: 500;
  font-size: 14px;
  box-sizing: border-box;
  white-space: nowrap;
  height: 100%;
}

.btn--updateProjections:disabled {
  opacity: 0.25;
  background: #FFFFFF;
  border: 0.5px solid rgba(98, 120, 140, 0.5);
}

.btn--updateProjections:hover {
  background: #F7F7F7;
  border: 0.5px solid rgba(98, 120, 140, 0.5);
}

.btn--updateProjections:active {
  background: #E8E9EA;
  border: 0.5px solid rgba(98, 120, 140, 0.5);
}

.btn--updateProjections svg {
  margin-right: 8px;
}

.Header--datePeriod {
  font-size: 11px;
  color: #666687;
  font-weight: 400;
  line-height: 16px;
  text-transform: uppercase;
}