footer {
    display: flex;
    gap:2rem;
    margin-top: 1.5rem;
    align-items: center;
    margin-left: 2.5rem;
}

footer button {
    font-size: 1.2rem;
    color: var(--Neutral800);
    background-color: transparent;
    display: flex;
    align-items: center;
    gap:1.3rem;
}

footer button:hover {
    font-size: 1.2rem;
    color: var(--Neutral800);
    background-color: white;
    display: flex;
    align-items: center;
    gap:1.3rem;
    box-shadow: 0px 1px 4px 0px #1A1A431A;
    font-weight: 700;

}

.PaymentPlan__Container {
    background: var(--Neutral170);
    margin: 2rem 2rem 0 2.6rem;
    overflow-x: auto;
    height: calc(100vh - 285px);
    /* width: 100%; */
    border: 1px solid var(--Neutral150);
    border-radius: 0.4rem;
}

.PaymentPlan__Header {
    display: flex;
    justify-content: flex-end;
    margin: 3rem 2rem 1rem 0;
}

.react-pdf__Page {
    width: 100%;
    
}

@media only screen and (max-width: 1026px) {
    .PaymentPlan__Container {
        height: calc(100vh - 324px);
    }
}
@media only screen and (max-width: 605px) {
    .PaymentPlan__Container {
        height: calc(100vh - 365px);
    }
}
