.AppInformation__Container {
    height: auto;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color:#e5e5e5
}

.AppInformation__Container__CenterCard {
background-color: white;
width: 144rem;
height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;

}

.AppInformation__Container__BannerImage {
    height: 20.9rem;
    width: 100%;
}

.AppInformation__Container__MDLogoImage{
    height: 5.1rem;
    width: 100%;
}

.AppInformation__Container__CenterCard__TextContent{
    width: 83.2rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    overflow-y: auto;
    padding-bottom: 2rem;
}

.AppInformation__Container__CenterCard__TextContent__Bold {
    color: #32324d;
    line-height: 24px;
    font-size: 16px;
    text-align: left;
    font-weight: 600;
}

.AppInformation__Container__CenterCard__TextContent__ParagraphHeader{
    color: #32324d;
    line-height: 24px;
    font-size: 16px;
    text-align: left;
    font-weight: 700;
}

.AppInformation__Container__CenterCard__TextContent__Paragraph{
    color: #32324d;
    line-height: 24px;
    font-size: 16px;
    text-align: left;
}