.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.25) !important;
}

.AddEmployee__Title {
  margin-left: 0rem;
}

.AddEmployee__Title h3 {
  margin-bottom: 2rem;
}

.MuiTypography-h6 .btn__edit__small {
  background: var(--Neutral800);
  border-style: solid;
  border-color: var(--Neutral800);
  display: inline-block;
  height: 19px;
  width: 44px;
  border-radius: 0.3rem;
  font-size: 0.9rem;
  color: white;
  text-align: center;
  line-height: 1.58rem;
  padding-bottom: 0.5rem;
}

.btn__edit__small svg {
  margin-left: 0rem;
  margin-right: 0.333rem;
}

.MuiTypography-h6 .btn__delete__small {
  background: #e24c4b;
  border-color: #e24c4b;
  border-style: solid;
  height: 19px;
  width: 54px;
  border-radius: 0.3rem;
  font-size: 0.9rem;
  color: white;
  text-align: center;
  line-height: 1.58rem;
  margin-left: 1rem;
  padding-bottom: 0.5rem;
}

.btn__delete__small {
  border: none;
  background-color: white;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  color: #e24c4b;
  cursor: pointer;
}

.btn__delete__small svg {
  margin-left: 0rem;
  margin-right: 1rem;

}

.modal__divider {
  left: 0;
  border: 0.1px solid #f4f4f4;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.MuiDialogContent-root {
  padding: 0 !important;
  background-color: white;
}

.css-ypiqx9-MuiDialogContent-root {
  padding: 0;
}

.EmployeeAddTable {
  padding: 0;
  border-radius: 10px;
  box-shadow: 0px 34px 40px rgba(98, 120, 140, 0.15);
  overflow: auto;
  margin-bottom: 1rem;
  height: auto;
  margin-bottom: 0rem !important;
  overflow: hidden;
}

.employeeAddContainer {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  align-items: center;
  height: 100%;
  align-items: flex-start;
  width: 100%;
  height: 7.1rem;
}

/* THIS CONTROL THE HEIGHT OF INPUT BOXES */
.employeeAddContainerParent {
  display: inline-flex;
  justify-content: left;
  width: 45%;
  margin-left: 2.2rem;
  height: 8rem;
  flex-direction: column;
  /* margin-bottom: 1.7rem; */
}

.employeeAddContainerParent_Note {
  display: flex;
  justify-content: left;
  margin-left: 2.2rem;
  margin-right: 1.65rem;
  height: 8.5rem;
}

.ErrorSection {
  height: 1.9rem;
}

.errorMsg {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: #e24c4b;
  margin-top: 4px;
  align-self: flex-end;
  width: 14rem;
  text-align: right;
  justify-self: center;
}

.replicate-input.error input {
  border: 1px solid #D02B20;
}

.replicate-input .errorMsg {
  width: 100%;
  font-size: 12px;
  color: #D02B20;
  margin-top: 0px;
}

.replicate-input label {
  color: #32324d;
}

.employeeAddContainerParent_Note textarea {
  padding-top: 1rem;
}

.employeeAddContainerParent_Note textarea::placeholder {
  color: var(--Neutral500);
}

#note {
  height: 100%;
  border: 0.5px solid rgba(98, 120, 140, 0.4);
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 1.2rem;
  padding-right: 1rem;
  width: 100%;
}

.employeeAddContainer label {
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.6rem;
  height: 1.6rem;
  display: flex;
  align-items: center;
  color: var(--Neutral800);
}

.EmployeeDetailRow--Value__StatusTag {
  margin-left: 1.6rem;
  font-size: 1.4rem;
  font-weight: 500;
  height: 30;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 0.5rem;
  line-height: 1.4rem;
}

.employeeAddContainer .employee_input {
  width: 100%;
  float: left;
  height: 4rem;
  border: 0.5px solid rgba(98, 120, 140, 0.4);
  box-sizing: border-box;
  border-radius: 0.4rem;
  padding-left: 1.2rem;
  padding-right: 1rem;
}

.employeeSalary,
.botSection__InputContainer input {
  width: 100%;
  float: left;
  height: 4rem;
  border: 0.5px solid rgba(98, 120, 140, 0.4);
  box-sizing: border-box;
  border-radius: 0.4rem;
  padding-left: 1.2rem;
  padding-right: 1rem;
}
.employeeSalary input {
  width: 100%;
  float: left;
  height: 4rem;
  border: 0.5px solid rgba(98, 120, 140, 0.4);
  border-radius: 0.4rem;
  padding-left: 1.2rem;
  padding-right: 1rem;
}

.employeeSalary,
.iconContainer_raisesbonuscommission {
  position: relative;
  border: none;
  padding: 0;
}

.employeeSalary::after {
  position: absolute;
  font-weight: 600;
  color: rgba(98, 120, 140, 0.5);
  line-height: 18px;
  font-size: 12px;
  right: 8%;
  top: 30%;
  margin-right: 1rem;
  content: "$/Annually";
}

.iconContainer_raisesbonuscommission::after {
  position: absolute;
  font-weight: 600;
  color: rgba(98, 120, 140, 0.5);
  line-height: 18px;
  font-size: 12px;
  right: 8%;
  top: 30%;
  margin-right: 1rem;
  content: "$";
}

.employeeAddContainer input::placeholder {
  color: var(--Neutral500);

  font-size: 1.4rem;
}

.select {
  width: 24.48rem;
  height: 4.5rem !important;
  border: 0.5px solid rgba(98, 120, 140, 0.4);
  box-sizing: border-box;
  border-radius: 10px;
  padding-left: 1.2rem;
  color: #dfdbdb;
}

/* select value container */
.css-g1d714-ValueContainer {
  align-items: center;
  display: flex;
  flex: 1 1;
  -webkit-box-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

/* select control */
.css-yk16xz-control {
  align-items: center;
  background-color: rgb(255, 255, 255);
  border: 0.5px solid rgba(98, 120, 140, 0.4) !important;
  border-radius: 10px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  transition: all 100ms ease 0s;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

/* when select control is selected */
.css-1pahdxg-control:hover {
  border-color: #62788c !important;
}

.css-1pahdxg-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: #62788c !important;
  border-radius: 10px;
  box-shadow: none !important;
  height: 100% !important;
  width: 100% !important;
}

/* select parent container */
.css-2b097c-container {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

.css-b8ldur-Input {
  margin: 0;
  padding: 0 !important;
  visibility: visible;
  color: hsl(0, 0%, 20%);
  box-sizing: border-box;
}

/* select place holder */
.css-1wa3eu0-placeholder {
  color: var(--Neutral500) !important;
  font-size: 1.4rem;
  font-weight: 400 !important;
  margin-left: 0.4rem !important;
  margin-right: 2px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
}

.vl {
  border-left: 1px solid #f4f4f4;
  height: 500px;
}


/* checkbox for raises and bonuses */
.MuiFormControlLabel-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  margin-left: 5px !important;
  margin-right: 16px;
}

.modal__divider {
  left: 0;
  border: 0.1px solid #f4f4f4;
  margin-top: 2rem;
  margin-bottom: 0.5rem !important;
}

.MuiCollapse-wrapper {
  margin-left: 2.2rem;
  margin-right: 2.2rem;
  width: auto !important;
}

.MuiCollapse-wrapperInner {
  width: 100% !important;
}

.botSection__Header {
  width: 224.96px;
  height: 21px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--Neutral800);
  margin: 1.2rem 1.5rem;
}

.botSection__Container {
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  padding: 0 1.6rem;
  padding-bottom: 14rem;
}

.botSection__Row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  gap: 2.2rem;
}

/* .botSection__Row ~ .botSection__Row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  gap: 2.2rem;
  background-color: #f7f7f7;
  border-top: 0.5px solid rgba(98, 120, 140, 0.4) !important;
  border-bottom: none;
  border-radius: 0px 0px 9px 9px;
} */

.botSection__InputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 1.5rem;
}

.botSection__InputContainer:nth-of-type(1) {
  margin-left: 1.5rem;
  margin-right: 0;
}

.botSection__InputContainer:nth-of-type(2) {
  margin-left: 0;
  margin-right: 1.5rem;
}

.botSection__InputContainer label {
  width: 56px;
  height: 21px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}



.css-1lvsv9w-menu {
  top: 120% !important;
  background-color: hsl(0, 0%, 100%);
  border-radius: 10px;
  box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
  margin-bottom: 8px;
  margin-top: 8px;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
}

.delete-dialog {
  width: fit-content;
}

.information--small {
  background: rgba(246, 246, 249, 1);
  padding: 2rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: rgba(50, 50, 77, 1);
  margin: 0 20px;
  border-radius: 10px;
  display: flex;
  gap: 1.2rem;
  align-items: center;
}