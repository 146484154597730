.CompanySettings__Container {
    padding: 2rem;
}

.CompanySettings__Container button {
    margin-top: 2rem;
}

.RollForward__Date {
    font-size: 2rem;
}