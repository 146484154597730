.tableContainer__TasksModule {
  background: #fff;
  padding: 0rem 2rem 0 2.6rem;
  overflow-x: auto;
  height: calc(100vh - 47px);
  min-width: 80.8rem;
}

.flex-row {
  display: flex;
  gap:1rem;
}

#danger:hover {
    color: var(--Danger600) !important;
    border: 1px solid var(--Danger200) !important;
    cursor: pointer;
}

#danger:hover svg {
    color: var(--Danger500) !important;
}

#danger:hover > svg > path {
    fill: var(--Danger500) !important;
}

.success:hover {
    color: var(--Success500) !important;
    border: 1px solid var(--Success500) !important;
    cursor: pointer;
}

.success:hover > span {
  color: var(--Success500) !important;
}

.success:hover svg {
    color: var(--Success500) !important;
    fill: var(--Success500) !important;
}

.success:hover > svg > path , #success:hover a > svg > path{
    fill: var(--Success500) !important;
}

.no-borders-no-background:hover,.no-borders-no-background, #success:hover.no-borders-no-background{
  background-color: transparent !important;
  border: none !important;
}

.AssigneeContainer.show {
  display: block !important;
}

.asanaUserInitials {
  margin-top:0.2rem;
  color: var(--Neutral600);
  font-size: 1.1rem;
}

.reduced-opacity{
  opacity: 0.5;
}

.reduced-opacity:hover{
  opacity: 1;
}

div[class$="-MuiPaper-root-MuiDialog-paper"] {
    /* width: 65.3rem;
    min-width: 65.3; */
    overflow-x:auto;
}

div[class$="MuiDialog-paper"] {
/* height: 90%; */
}


.alert-dialog-task-form > .MuiDialog-container > .MuiDialog-paper >.MuiDialogTitle-root {
    height: 6.4rem !important;
}

textarea {
    width: 90%;
    margin-top: 0.5rem;
    font-weight: 400;
}

input:focus-visible, input:focus, textarea:focus-visible, textarea:hover, select:focus-visible, input:hover {
    outline: 1px solid var(--Neutral200);
    outline-offset: 0;
}

/* button {
    margin: 1rem 0rem;
} */

h4 {
    margin-bottom: 1rem;
}

label {
    font-weight: 400;
}

.buttonsContainer{
  display: flex;
  gap: 1rem;
}

.buttonsContainer label {
  margin-bottom: 0px;
}

.buttonsContainer > div > svg {
  width: 1.5rem;
}

.btn--secondary > svg > path {
    fill: var(--Primary600);
}



.completeButton{
    margin-right: auto;
}

.completeButton button:hover {
background-color: var(--Success100) !important;
}

.btn--secondary--completed > svg > path {
    fill: var(--Success500);
}

.Likes {
  font-size: 1.4rem;
}

.Parents__Container {
  margin-left: 1.6rem;
  margin-top: 0.5rem;
}
.Parents__Container > div {
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--Neutral600);
  text-decoration-line: underline;
  cursor: pointer;
}

.Parents__Container > div:hover{
  color: var(--Neutral400);
}

.Parents__Container > div > span > svg {
  font-size: 1rem;
}


.TaskCard__Title{
    font-size: 1.8rem;
    width: 98% !important;
    padding: 1.6rem 1.1rem 1.6rem 1.1rem;
    margin: 1rem auto 0rem auto;
}

.TaskCard__Container {
padding: 1.6rem;
width: 65rem;

}

.buttonsContainer {
  height: 3.2rem;
}

.TaskCard__Line {
    display: flex;
    gap: 1rem;
    font-size: 1.3rem;
    margin-bottom: 2rem;
    height: 3rem;
    color: var(--Neutral900);
    align-items: center;
}

.TaskCard__Line .react-datepicker-wrapper >  .react-datepicker__input-container > .calendar-custom-input {
  gap: 1rem;
}

.TaskCard__Line .react-datepicker-wrapper >  .react-datepicker__input-container > .calendar-custom-input span {
  margin-left: 1rem;
}



.TaskCard__Label {
    color: var(--Neutral600);
    font-size: 1.4rem;
    width: 11rem;
}

.TaskCard__Attachments__Area {
    display: flex;
    flex-wrap: wrap;
    gap:1rem;
    width: 100%;
    max-width: 80rem;
    height: auto;
    margin: 2rem 0;
}

.TaskCard__Attachments__Area__Container {
    display: flex;
    align-items: center;
    gap:1rem;
    width: auto;
    max-width: 24rem;
    height: auto;
    max-height: 10rem;
    border: 1px solid var(--Neutral150);
    border-radius: 0.4rem;
    padding:0.5rem 1rem;
}

.TaskCard__Attachments__Area__Container > svg{
  width: 3.6rem;
}

.TaskCard__Attachments__Area__Container.dashed-border > svg{
  width: 2.5rem;
}

.btn--tertiary svg {
    color: var(--Neutral300)
}

.btn--tertiary > svg > path{
    fill: var(--Neutral300)
}

/* .btn--tertiary >span{
  color:var(--Neutral300);
} */

.dashed-border {
    border-style: dashed;
    padding:1rem 1rem;
}

.TaskCard__Attachments__Area__Container__Name {
    width: 100%;
    height: auto;
    text-overflow: ellipsis;
    word-wrap: none;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 0.5rem;
}

.TaskCard__StoriesContainer {
    padding: 1rem 2.5rem;
}

.TaskCard__StoriesContainer__Comment__Container {
    font-size: 1.6rem;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 0.8rem;
}

.TaskCard__StoriesContainer__Comment__Container__Main {
  width: 100%;
}

.TaskCard__StoriesContainer__Comment__Container__Header{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.TaskCard__StoriesContainer__updateLine {
    font-size: 1rem;
    
}

.TaskCard__StoriesContainer__storyTimeStamp{
    font-size: 0.8rem;
    color: var(--Neutral600);
    margin-right: auto;
}

.TaskCard__SubTasksContainer{
    margin-top: 2rem;
}

.TaskCard__SubTasksContainer__Body{
    padding-left: 0.5rem;
}



.TaskCard__Attachments__Container__ListContainer {
    padding-left: 0.5rem;
}   

.TaskCard__Attachments__Area__Container__Buttons{
    display: flex;
    align-items:flex-start !important;
    justify-content: flex-start !important;
    height: auto;
    gap:0.5rem;
}

.addSubTask__Button {
    width: auto;
    max-width: 12.6rem;
    padding:0.5rem 0.7rem;
    margin: 1rem 0rem 2rem 0rem;
    color: #38434C;
}

.addComment__Button {
  width: auto;
  padding:0.5rem 0.7rem;
  /* margin: 1rem 0rem 2rem 0rem; */
}

.addSubTask__Button > svg {
    width: 1.5rem;
    height: 1.5rem;
}

.addSubTask__Button > svg > path {
fill:var(--Neutral300)
}

.TaskCard__SubTasksContainer__SubTask {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--Neutral600);
    position: relative;
    padding-left: 0.3rem;
    cursor: pointer;
    height: 4rem;
}

.completed > svg {
    color: var(--Success500) !important;
    font-size: 1.5rem;
}

.TaskCard__SubTasksContainer__SubTask > div > svg {
    color: var(--Neutral400);
    font-size: 1.5rem;
}
.TaskCard__SubTasksContainer__SubTask > svg:hover{
    color: var(--Success500);
}

.TaskCard__SubTasksContainer__SubTask:hover {
    background-color: var(--Success050);
}



.TaskCard__SubTasksContainer__SubTask__TextInput{
    position: relative;
    margin-top: 0 !important;
    background-color: transparent;
    display: flex;
    font-size: 1.4rem;
    line-height: 1.6rem;
    padding:10px;
}

.TaskCard__SubTasksContainer__SubTask__TextInput:hover, .TaskCard__SubTasksContainer__SubTask__TextInput:focus-visible {
    outline: none !important;
}

.TaskCard__SubTasksContainer__SubTask__ButtonsContainer {
    position: absolute;
    display: flex;
    gap: 0.5rem;
    top:3px;
    right: 0.3rem;
    align-items: center;
    height: 3rem;
}

.TaskCard__SubTasksContainer__SubTask__ButtonsContainer > div > svg {
  margin-top: 0.3rem;
}

.TaskCard__SubTasksContainer__SubTask__ButtonsContainer.single > .react-datepicker-wrapper{
    margin-top:0.3rem;
}
.TaskCard__Line > .react-datepicker-wrapper {
    width: 5.4rem !important;
}
.TaskCard__Line > .react-datepicker-wrapper > .react-datepicker-wrapper, .TaskCard__Line > .react-datepicker-wrapper{
    width: 10.8rem !important;
}

.TaskCard__SubTasksContainer__SubTask__ButtonsContainer > .react-datepicker-wrapper > .react-datepicker__input-container > .calendar-custom-input > svg {
    font-size: 2.4rem !important;
    display: none;
  }

  .TaskCard__SubTasksContainer__SubTask__ButtonsContainer > .react-datepicker-wrapper > .react-datepicker__input-container > .calendar-custom-input > svg:hover {
    opacity: 1;
  }

  /* .calendar-custom-input {
    height: 2.4rem;
  } */

.TaskCard__SubTasksContainer__SubTask__ButtonsContainer button {
    margin-bottom: 0px 0px;
    transform: translateY(1px);
    display: none !important;
}
.TaskCard__SubTasksContainer__SubTask:hover .TaskCard__SubTasksContainer__SubTask__ButtonsContainer > .react-datepicker-wrapper > .react-datepicker__input-container > .calendar-custom-input > svg {
display: inline;
}

.TaskCard__SubTasksContainer__SubTask:hover .TaskCard__SubTasksContainer__SubTask__ButtonsContainer > button {
    display: block !important;
}

.TaskCard__SubTasksContainer__SubTask.active .TaskCard__SubTasksContainer__SubTask__ButtonsContainer > button{
  display: block !important;
}

.react-datepicker-wrapper {
    display: flex;
    align-items: center;
    width: auto !important;
}

.user__circle {
    display: inline-block;
    background-color: #5900B2;
    border-radius: 50%;
    height: 2.8rem;
    width: 2.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .user__circle_inner {
    color: white;
    display: table-cell;
    vertical-align: middle; 
    text-align: center;
    text-decoration: none;
    font-size: 11px;
    line-height: 11px;
  }

  .SelectedAssigneeName {
    color: var(--Neutral900);
  }

  .TaskCard__StoriesContainer__Comment__Container__Body {
    font-size: 1.3rem;
  }



  .not-completed:hover > svg{
    color:var(--Success500)
  }

  .TaskCard__StoriesContainer__storyTimeStamp {
    margin-left: 0.5rem;
    color: var(--Neutral400);
  }

  .TaskCard__StoriesContainer__toggleStories {
    color: var(--Primary600);
    font-size: 1.2rem;
    cursor: pointer;
  }

  .TaskCard__Line > div[class$="-container"] {
    width: 30%  !important;
  }

  .TaskCard__Line > div[class$="-container"]  > div[class$="-control"]{
    height: 3rem  !important;
    min-height: 3rem;
    align-content: center !important;
  }

  .TaskCard__Attachments__Area__Container > div[class$="-container"],
  .TaskCard__SubTasksContainer__SubTask__ButtonsContainer > div[class$="-container"]
  {
    width:3rem;
  }

  .TaskCard__Attachments__Area__Container > div[class$="-container"] > div[class$="-control"] > div[class$="-ValueContainer"],
  .TaskCard__SubTasksContainer__SubTask__ButtonsContainer > div[class$="-container"] > div[class$="-control"] > div[class$="-ValueContainer"]{
    /* display: none; */
    height: 0rem;
  }

  .TaskCard__Attachments__Area__Container > div[class$="-container"] > div[class$="-control"],
  .TaskCard__SubTasksContainer__SubTask__ButtonsContainer > div[class$="-container"] > div[class$="-control"]
  {
    border: none;
    width: 2.5rem;
    top: -4px;
    background-color: transparent;
  }
  
  .TaskCard__Attachments__Area__Container > div[class$="-container"] > div[class$="-menu"],
  .TaskCard__SubTasksContainer__SubTask__ButtonsContainer > div[class$="-container"] > div[class$="-menu"]
  {
    margin-top: 0rem;
    top:3.5rem;
    width: 20rem;
  }

  .TaskCard__Attachments__Area__Container > div[class$="-container"] > div[class$="-control"] > div[class$="-IndicatorsContainer"],
  .TaskCard__SubTasksContainer__SubTask__ButtonsContainer > div[class$="-container"] > div[class$="-control"] > div[class$="-IndicatorsContainer"]
  {
    display: none !important;
  }

  .TaskCard__Attachments__Area__Container:hover > div[class$="-container"] > div[class$="-control"] > div[class$="-IndicatorsContainer"],
  .TaskCard__SubTasksContainer__SubTask:hover .TaskCard__SubTasksContainer__SubTask__ButtonsContainer > div[class$="-container"] > div[class$="-control"] > div[class$="-IndicatorsContainer"]
  {
    display: block !important;
    height: 3rem;
  }

  div[class$="-MuiDialogActions-root"] {
    background-color: var(--Neutral100);
  }

  .NewComment__Container {
    width: 100%;
    padding: 1rem 1rem;
    position:sticky;
    bottom:0rem;
    background-color: var(--Neutral100);
  }

  .NewComment__Container > textarea {
    border: 1px solid var(--Neutral150);
  }
  
  /* .NewComment__Container > textarea:focus{
    height: 10.3rem !important;
  } */

  .NewComment__Container > button {
    position:absolute;
    bottom:13px;
    right:15px;
  }

  .Empty > div[class$="-container"] > div[class$="-control"] > div[class$="-ValueContainer"] > div[class$="-singleValue"]{
    border:1px solid var(--Neutral100);
    border-style: solid !important;
  }

  .status > div[class$="-container"] > div[class$="-control"] {
    border-style: none;
    height: 3rem !important;
    min-height: 3rem;
  }

  .status > div[class$="-container"] > div[class$="-control"]{
    border-style: none;
    box-shadow: none;
    background-color: none;
    box-shadow: none !important;
  }

  .status > div[class$="-container"] > div[class$="-menu"] {
    width: 15rem;
  }

  .status > div[class$="-container"] > div[class$="-control"] > div[class$="-IndicatorsContainer"] > div[class$="-indicatorContainer"] {
    display: none !important;
  }

  .status > div[class$="-container"] > div[class$="-control"] > div[class$="-ValueContainer"] {
    padding: 0rem;
  }

  .status > div[class$="-container"] > div[class$="-control"] > div[class$="-ValueContainer"]:focus {
    outline: none;
  }

  .status > div[class$="-container"] > div[class$="-control"] > div[class$="-ValueContainer"] > div[class$="-singleValue"] {
    border-radius: 5rem;
    padding: 0rem 0.7rem 0.2rem 0.7rem;
    color: white !important;
  }

  .Not.Started.status > div[class$="-container"] > div[class$="-control"] > div[class$="-ValueContainer"] > div[class$="-singleValue"]{
    background-color: #EE5E52;
  }

  .On.Deck.status > div[class$="-container"] > div[class$="-control"] > div[class$="-ValueContainer"] > div[class$="-singleValue"]{
    background-color: #F29D41;
  }

  .In.Progress.status > div[class$="-container"] > div[class$="-control"] > div[class$="-ValueContainer"] > div[class$="-singleValue"]{
    background-color: rgba(133, 180, 64, 0.75);
  }

  .Waiting.Internal.status > div[class$="-container"] > div[class$="-control"] > div[class$="-ValueContainer"] > div[class$="-singleValue"]{
    background-color: #0C75AF;
  }

  .Waiting.External.status > div[class$="-container"] > div[class$="-control"] > div[class$="-ValueContainer"] > div[class$="-singleValue"]{
    background-color: #66B7F1;
  }

  .Complete.status > div[class$="-container"] > div[class$="-control"] > div[class$="-ValueContainer"] > div[class$="-singleValue"]{
    background-color: #5CB176;
  }

  .Low.status > div[class$="-container"] > div[class$="-control"] > div[class$="-ValueContainer"] > div[class$="-singleValue"]{
    background-color: #C0C0CF;
  }

  .Medium.status > div[class$="-container"] > div[class$="-control"] > div[class$="-ValueContainer"] > div[class$="-singleValue"]{
    background-color: #F29D41;
  }

  .High.status > div[class$="-container"] > div[class$="-control"] > div[class$="-ValueContainer"] > div[class$="-singleValue"]{
    background-color: #EE5E52;
  }

  .AssigneeContainer__21 > svg {
    font-size: 1.5rem;
  }

  .AssigneeContainer__27 > svg {
    font-size: 2.1rem;
  }

  .AssigneeContainer__36 > svg {
    font-size: 2.7rem;
  }

  .AssigneeContainer > svg >path{
    fill: var(--Neutral200);
  }

  .SelectedAssigneeName > svg{
    margin-left: 1rem;
    color: var(--Neutral300);
    cursor: pointer;
  }

  .SelectedAssigneeName > svg:hover{
    margin-left: 1rem;
    color: var(--Neutral90);
    cursor: pointer;
  }

  .placeholder {
    color: var(--Neutral300);
    font-weight: 300;
  }

  .AsanaConnectContainer {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 16.8rem;
  }

  .AsanaConnectContainer button {
    width: 26rem;
    margin-top: 3rem;
  }

  .AsanaConnectContainer h2 {
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin-top: 1.7rem;
  }

  .AsanaConnectContainer span {
    color: var(--Neutral600);
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .ImageContainer {
    position: relative;
  }

  .asana-logo {
    position: absolute;
    left: 4.5rem;
  }

  .asana_logo__no_access {
    /* position: absolute;
    top: -1rem;
    left: 13.6rem; */
    transform: translateY(5.5rem);
    scale: 0.5;
  }
  
  .verifyingAccess__Container__ImageContainer__MainMessage {
    margin-top: 2.5rem;
    color: var(--Neutral900);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
  }

  .verifyingAccess__Container__ImageContainer__CallToActionMessage {
    color: var(--Neutral600);
    text-align: center;

    /* Epsilon - Subtitle */
    font-family: SF Pro Text;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-top: 2rem;
    width: 40rem;
  }

  .verifyingAccess__Container__ImageContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .fetchTasks__Container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 20rem;
    flex-direction: column;
    gap:2rem;
  }

  .verifyingAccess__Container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 25rem;
    flex-direction: column;
    gap:2rem;
  }

  .verifyingAccess__Container span {
    font-size: 2rem;
  }

  .NoAccess__Container{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 20rem;
    flex-direction: column;
    font-size: 1.7rem;
    color: var(--Danger600);
    gap:2rem;
  }

  .taskName__Cell {
    display: flex;
    gap:1rem;
    align-items: center;
    margin-left: 0.7rem;
  }

  .taskName__Cell svg {
    margin-top: 0.2rem;
  }

  .sectionCell__Count {
    color: var(--Neutral500);
    font-weight: 400;
  }
