/* .alert-dialog-users-add-form > .MuiDialog-container > .MuiDialog-paper > .MuiDialogContent-root > .userAddTable{
  min-width: 40rem !important;
  min-height: 30rem !important;
  max-width: 60rem;
} */

.alert-dialog-users-add-form>.MuiDialog-container>.MuiDialog-paper>.MuiDialogTitle-root {
  height: auto;
  padding: 1.6rem 2rem !important;
  background-color: var(--Neutral100);
  font-size: 1.7rem;
  line-height: 1.6rem;
  color: var(--Neutral800);
  font-style: normal;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}

.alert-dialog-users-add-form>.MuiDialog-container>.MuiDialog-paper>.MuiDialogActions-root {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  padding: 1.7rem 1.6rem;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--Neutral100);
  font-size: 1.2rem;
  gap: 2.1rem;
}

.userAddTable {
  padding: 2.4rem;
  border-radius: 10px;
  box-shadow: 0px 34px 40px rgb(98 120 140 / 15%);
  overflow: auto;
  height: auto;
  min-height: 21rem;
  margin-bottom: 0rem !important;
  width: 55rem;
  /* min-height: 30rem !important; */
}

.userAddContainer > div[class$="-container"] > div[class$="-menu"] {
  position: relative !important;
}

.css-ypiqx9-MuiDialogContent-root {
  padding: 0;
}

/* THIS CONTROL THE HEIGHT OF INPUT BOXES */
.userAddContainerParent {
  display: inline-flex;
  justify-content: left;
  width: 100%;
  height: auto;
  flex-direction: column;
}

.errorMsg {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: #e24c4b;
  margin-top: 4px;
  align-self: flex-end;
  width: 14rem;
  text-align: right;
  justify-self: center;
}

.notify-input {
  margin-left: auto;
}

.notify-input.error input {
  border: 1px solid #D02B20;
}

.notify-input .errorMsg {
  width: 100%;
  font-size: 12px;
  color: #D02B20;
  margin-top: 0;
}

.notify-input label {
  color: #32324d;
  margin-bottom: 0;
}

.userAddContainer .checkBoxContainer input {
  height: auto;
}

.userAddContainer {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  align-items: center;
  height: 100%;
  align-items: flex-start;
  width: 100%;
  gap:0.5rem;
}

.userAddContainer__IndentedContainer {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  align-items: center;
  height: 100%;
  align-items: flex-start;
  width: 100%;
  /* gap:0.5rem; */
  padding-left: 2rem;
  border: 1px solid rgba(98, 120, 140, 0.4);
  border-radius: 4px;
  padding-bottom: 2rem;
  padding-right: 2rem;
  padding-top: 0.5rem;
  margin-bottom: 1rem;

}

.userAddContainer label{
  font-style: normal;
  font-weight: 600 !important;
  font-size: 1.5rem;
  height: 2rem;
  margin: 0.75rem 0;
  color: var(--Neutral800);
}

.userAddContainer label span{
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  height: 2rem;
  margin: 0.75rem 0;
  color: var(--Neutral800);
}

.userAddContainer label span:nth-child(1){
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  height: 2.3rem;
  margin: 0.75rem 0;
  color: var(--Neutral300);
}

.userAddContainer input {
  width: auto;
  float: left;
  height: 4rem;
  border: 0.5px solid rgba(98, 120, 140, 0.4);
  box-sizing: border-box;
  border-radius: 0.4rem;
  padding-left: 1.2rem;
  padding-right: 1rem;
}

.userAddContainer input:hover {
  border: none;
}

.userAddContainer input[type="email"] {
  width: 100%;
}

.userAddContainer input::placeholder {
  color: var(--Neutral500);
  font-size: 1.4rem;
}

.MuiFormControlLabel-root {

  margin-left: 0 !important;
}