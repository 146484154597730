.bi-header__select{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 33px;
    justify-self: flex-end;
    margin-right: 1rem;
}

.bi-header__select > div:first-child{
    width: 11rem;
}

.bi-header__select > div:nth-child(2){
    width: 13rem;
}

.css-yk16xz-control {
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    position: relative;
    transition: all 100ms ease 0s;
    box-sizing: border-box;
    outline: 1px !important;
}


.css-1hwfws3 {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex: 1 1 0%;
    flex-wrap: wrap;
    padding: 2px 8px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}

.css-1uccc91-singleValue {
    color: var(--Neutral800) !important;
    margin-left: 2px;
    margin-right: 2px;
    max-width: calc(100% - 8px);
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    font-size: 1.4rem !important;
    font-family: "SF Pro Text" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    
}

.css-1g6gooi {
    margin: 2px;
    padding-bottom: 2px;
    padding-top: 2px;
    visibility: visible;
    color: rgb(51, 51, 51);
    box-sizing: border-box;
}
.css-8mmkcg {
    display: inline-block;
    fill:currentColor;
    line-height: 1;
    stroke: currentcolor;
    stroke-width: 0;
}

.css-tlfecz-indicatorContainer {
    color: rgb(204, 204, 204);
    display: flex;
    padding: 8px;
    transition: color 150ms ease 0s;
    box-sizing: border-box;
}


.css-1okebmr-indicatorSeparator {
    display: none !important;
    align-self: stretch;
    background-color: rgb(204, 204, 204);
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    width: 0px !;
    box-sizing: border-box;
}


.css-1lvsv9w-menu {
    FONT-VARIANT: JIS04;
    top: 120% !important;
    background-color: hsl(0, 0%, 100%);
    border-radius: 10px;
    box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
    /* margin-bottom: 8px; */
    /* margin-top: 8px; */
    position: absolute;
    width: 100%;
    box-sizing: border-box;
}



.css-g1d714-ValueContainer {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 2px 8px;
    -webkit-overflow-scrolling: touch;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}

.label {
    height: 2.1rem;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2.1rem;
    color: #000000;
    margin-top: 10px;
    display: block;
}


.checkBoxContainer{
    display:inline-block;
    transform: translate(0px, 2px);
    margin-right: 1rem;
}


.bi-header__select > div:first-child .select__label--inContainer{
    display:block;
    margin-right: 3.7rem;
}

.bi-header__select > div:nth-child(2) .select__label--inContainer{
    display:block;
    margin-right: 5.7rem;
}

.select__label--inContainer {
    margin-top:0.8rem;
    margin-left:1rem;
    color: #909DB5;
    font-weight: 400;
    font-size: 0.9rem;
    margin-bottom: -0.7rem;
}

/* select control container */
.css-1vf0xhf-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0!important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    top: 0;
    margin-left: auto;
}

.css-4ljt47-MenuList {
    max-height: 200px !important;
    overflow-y: auto;
    padding-bottom: 4px;
    padding-top: 4px;
    position: relative;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    border-radius: 0.4rem;
    scrollbar-width: thin;
}