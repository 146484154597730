.ForgotPassword__Container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;
}

.ForgotPassword__Container h1 {
    text-align: center;
    height: 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 40px;
    letter-spacing: 0.01em;
    color: var(--Neutral800);
    margin-top: -2rem;
    margin-left: -0.5rem;
}

.ForgotPassword__Container__EntryContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0.5rem;
    width: 100%;
}

.ForgotPassword__Container__EntryContainer span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 21px;
    color: #000000 !important;
}

.ForgotPassword__Container__EntryContainer label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #000000;
    margin: 1em 0;
}

.ForgotPassword__Container__EntryContainer input {
    padding: 1.4rem 1.8rem;
    border: 0.5px solid rgba(98, 120, 140, 0.4);
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.Auth__BottomSection {
    margin-left: 0rem;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formErrors {
    color: red !important;
    font-size: 11px !important;
    font-weight: 500 !important;
    line-height: 1.5 !important;
    margin-top: -6px;
    margin-bottom: 6px;
}
/* 
.Auth__GoogleBtn {
    display: flex;
    width: 100%;
}

.Auth__GoogleBtn button {
    display: flex;
    width: 100%;
    border: none !important;
    border: 1px solid #F4F4F4;
    box-sizing: border-box;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05) !important;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
} */